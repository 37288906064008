'use client';

import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Note} from '@/lib/notes/Note';
import {toast} from 'react-hot-toast';
import {useSearchHistory} from '@/hooks/useSearchHistoryStore';
import {useRewrite} from '@/hooks/useRewrite';
import {__} from '@/utils/translationUtils';

interface NoteEditorContextValue {
    note: Note;
    content: string;
    setContent: (content: string) => void;
    versionIndex: number;
    setVersionIndex: (index: number) => void;
    hasChanges: boolean;
    isSaving: boolean;
    isTranslating: boolean;
    isRewriting: boolean;
    handleSave: () => Promise<Note | undefined>; // Updated return type
    handleDiscard: () => void;
    handleTranslate: (targetLanguage: string) => Promise<void>;
    handleRewrite: (action: string, role?: string, tweakInstruction?: string) => Promise<void>;
    handleVersionChange: (newIndex: number) => void;
    totalVersions: number;
    onUpdate?: (note: Note) => void;
}

const NoteEditorContext = createContext<NoteEditorContextValue | undefined>(undefined);

export const useNoteEditor = () => {
    const context = useContext(NoteEditorContext);
    if (!context) {
        throw new Error('useNoteEditor must be used within a NoteEditorProvider');
    }
    return context;
};

interface NoteEditorProviderProps {
    note: Note;
    children: React.ReactNode;
    onUpdate?: (note: Note) => void;
}

export const NoteEditorProvider: React.FC<NoteEditorProviderProps> = ({
                                                                          note,
                                                                          children,
                                                                          onUpdate,
                                                                      }) => {
    // Add explicit content version tracking
    const [content, setContent] = useState(note.content || '');
    const [versionIndex, setVersionIndex] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [isTranslating, setIsTranslating] = useState(false);
    const {updateItem} = useSearchHistory();
    const {isRewriting, handleRewrite: rewrite} = useRewrite(onUpdate);

    // Track content versions explicitly
    const versionedContent = useMemo(() => {
        return [
            note.content || '',
            ...(note.versions?.map(v => v.content) || [])
        ];
    }, [note.content, note.versions]);

    const totalVersions = versionedContent.length;

    // Unified version content access
    const getCurrentVersionContent = useCallback((index: number) => {
        return versionedContent[index] || '';
    }, [versionedContent]);

    // More accurate hasChanges calculation
    const hasChanges = useMemo(() => {
        return content !== getCurrentVersionContent(versionIndex);
    }, [content, versionIndex, getCurrentVersionContent]);

    // Enhanced version synchronization
    useEffect(() => {
        const latestContent = getCurrentVersionContent(0);
        if (versionIndex === 0 && content !== latestContent) {
            setContent(latestContent);
        }
    }, [note.id, getCurrentVersionContent, versionIndex]);

    // Improved save handler with version tracking
    const handleSave = async () => {
        if (!hasChanges) return;

        setIsSaving(true);
        try {
            const response = await fetch('/api/audio/edit', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({id: note.id, newText: content}),
            });

            if (!response.ok) throw new Error('Failed to update note');

            const updatedNote: Note = await response.json();
            if (updatedNote) {
                // Update local state to match server response
                setContent(updatedNote.content || '');
                setVersionIndex(0);
                updateItem(updatedNote);
                onUpdate?.(updatedNote);
                toast.success(__('Changes saved successfully'));
                return updatedNote;
            }
        } catch (error) {
            console.error('Failed to save changes:', error);
            toast.error(__('Failed to save changes'));
            throw error;
        } finally {
            setIsSaving(false);
        }
    };

    const handleDiscard = useCallback(() => {
        setContent(getCurrentVersionContent(versionIndex));
    }, [versionIndex, getCurrentVersionContent]);

    const ensureLatestVersion = async () => {
        if (hasChanges) {
            return await handleSave();
        }
        return note;
    };

    const handleTranslate = async (targetLanguage: string) => {
        try {
            setIsTranslating(true);

            // Ensure we're working with the latest version if there are changes
            const latestNote = hasChanges ? await handleSave() : note;
            if (!latestNote) return;

            // Get the version ID from the current index
            const versionId = getCurrentVersionContent(versionIndex);

            const response = await fetch('/api/translate', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: latestNote.id,
                    targetLanguage,
                    versionId,
                }),
            });

            if (!response.ok) throw new Error('Translation failed');

            const updatedNote = await response.json();
            updateItem(updatedNote);
            onUpdate?.(updatedNote);
            setVersionIndex(0); // Point to the newly created version
            setContent(updatedNote.content || '');
            toast.success(__('Translation successful'));
        } catch (error) {
            console.error('Translation error:', error);
            toast.error(__('Translation failed'));
        } finally {
            setIsTranslating(false);
        }
    };

    const handleRewrite = async (action: string, role?: string, tweakInstruction?: string) => {
        try {
            // Ensure we're working with the latest version if there are changes
            const latestNote = hasChanges ? await handleSave() : note;
            if (!latestNote) return;

            // Fix: Get the proper version ID
            const versionId = versionIndex > 0 && latestNote.versions?.[versionIndex - 1] 
                ? latestNote.versions[versionIndex - 1].id 
                : undefined;
            
            const textToRewrite = getCurrentVersionContent(versionIndex);

            const updatedNote = await rewrite({
                id: latestNote.id,
                action,
                role,
                text: textToRewrite,
                versionId,
                tweakInstruction,
            });

            if (updatedNote) {
                // Update the note reference to the latest version
                updateItem(updatedNote);
                onUpdate?.(updatedNote);

                // Set version index to 0 (latest version)
                setVersionIndex(0);

                // Set content to match the new version exactly
                setContent(updatedNote.content || '');

                // Update our local note reference
                note = updatedNote;
            }
        } catch (error) {
            console.error('Rewrite error:', error);
            toast.error(__('Failed to rewrite text'));
        }
    };

    const handleVersionChange = useCallback((newIndex: number) => {
        if (hasChanges) {
            if (window.confirm(__('You have unsaved changes. Discard them?'))) {
                setVersionIndex(newIndex);
                // When changing versions, ensure we set the content to exactly match the version
                setContent(newIndex === 0 ? note.content || '' : note.versions[newIndex - 1].content || '');
            }
        } else {
            setVersionIndex(newIndex);
            // Same here - exact match to avoid unsaved changes state
            setContent(newIndex === 0 ? note.content || '' : note.versions[newIndex - 1].content || '');
        }
    }, [hasChanges, note]);

    const value = {
        note,
        content,
        setContent,
        versionIndex,
        setVersionIndex,
        hasChanges,
        isSaving,
        isTranslating,
        isRewriting,
        handleSave,
        handleDiscard,
        handleTranslate,
        handleRewrite,
        handleVersionChange,
        totalVersions,
        onUpdate,
    };

    return (
        <NoteEditorContext.Provider value={value}>
            {children}
        </NoteEditorContext.Provider>
    );
};

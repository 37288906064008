'use client';

import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {UserSettings} from '@/lib/services/userSettingsService';
import debounce from 'lodash/debounce';
import { __ } from '@/utils/translationUtils';
import { showToast } from '@/utils/toast';

interface SettingsContextType extends UserSettings {
    isLoading: boolean;
    error: string | null;
    updateSettings: (newSettings: Partial<UserSettings>) => Promise<void>;
    updateRecentLanguages: (language: string) => Promise<void>;
    updateRecentRoles: (role: string) => Promise<void>;
    incrementRoleUsage: (role: string) => Promise<void>;
    updateRecentTweaks: (tweak: string) => Promise<void>;
    updateConversationFontSize: (recordingId: string, fontSize: number) => Promise<void>;
    updateConversationLastLanguage: (recordingId: string, language: string) => Promise<void>;
    initializeSettings: () => Promise<void>;
}

const defaultSettings: UserSettings = {
    autocopy: false,
    playbackSpeed: 1.0,
    lastVoice: 'alloy',
    bubblePosition: {x: 20, y: 20},
    lastsstortts: '',
    dismissedMobileWarning: false,
    recentLanguages: [],
    selectedVoice: 'alloy',
    autoplayTTS: false,
    recentTweaks: [],
    recentRoles: [],
    conversationFontSizes: {},
    conversationLastLanguages: {},
    recentConversationLanguages: [],
    transcriptionLanguage: 'auto'
};

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const SettingsProvider: React.FC<{
    children: React.ReactNode
}> = ({children}) => {
    const [settings, setSettings] = useState<UserSettings>(defaultSettings);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const updateSettingsAPI = async (newSettings: Partial<UserSettings>): Promise<UserSettings> => {
        const response = await fetch('/api/user/settings', {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newSettings),
        });
        if (!response.ok) throw new Error('Failed to update settings');
        return response.json();
    };

    const updateSettingsWithOptimisticUI = useCallback(async (newSettings: Partial<UserSettings>): Promise<void> => {
        // Store current settings for potential rollback
        const previousSettings = { ...settings };
        
        // Immediately update the UI optimistically
        setSettings(prevSettings => ({
            ...prevSettings,
            ...newSettings,
            // Preserve nested objects
            conversationFontSizes: {
                ...prevSettings.conversationFontSizes,
                ...(newSettings.conversationFontSizes || {})
            },
            conversationLastLanguages: {
                ...prevSettings.conversationLastLanguages,
                ...(newSettings.conversationLastLanguages || {})
            }
        }));
        
        // Start loading state
        setIsLoading(true);
        setError(null);

        try {
            const updatedSettings = await updateSettingsAPI(newSettings);
            setSettings(prevSettings => ({
                ...prevSettings,
                ...updatedSettings,
                conversationFontSizes: {
                    ...prevSettings.conversationFontSizes,
                    ...updatedSettings.conversationFontSizes
                },
                conversationLastLanguages: {
                    ...prevSettings.conversationLastLanguages,
                    ...updatedSettings.conversationLastLanguages
                }
            }));
        } catch (err) {
            // Revert to previous settings on error
            setSettings(previousSettings);
            console.error('Error updating user settings:', err);
            
            // Show appropriate error message based on error type
            let errorMessage = __('Failed to update settings. Please try again later.');
            if (err instanceof TypeError && err.message === 'Failed to fetch') {
                errorMessage = __('Network error. Please check your connection and try again.');
            }
            
            setError(errorMessage);
            showToast(errorMessage, 'error');
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, [settings]);

    const updateSettings = useCallback((newSettings: Partial<UserSettings>): Promise<void> => {
        return updateSettingsWithOptimisticUI(newSettings);
    }, [updateSettingsWithOptimisticUI]);

    const updateRecentLanguages = useCallback((language: string): Promise<void> => {
        const updatedLanguages = [language, ...settings.recentLanguages.filter(lang => lang !== language)].slice(0, 5);
        return updateSettingsWithOptimisticUI({recentLanguages: updatedLanguages});
    }, [settings, updateSettingsWithOptimisticUI]);

    const updateRecentRoles = useCallback((role: string): Promise<void> => {
        const updatedRoles = [...settings.recentRoles];
        const existingRoleIndex = updatedRoles.findIndex(r => r.name === role);

        if (existingRoleIndex === -1) {
            updatedRoles.push({name: role, count: 1});
        }

        const sortedRoles = updatedRoles
            .sort((a, b) => b.count - a.count)
            .slice(0, 20);

        return updateSettingsWithOptimisticUI({recentRoles: sortedRoles});
    }, [settings, updateSettingsWithOptimisticUI]);

    const incrementRoleUsage = useCallback((role: string): Promise<void> => {
        const updatedRoles = [...settings.recentRoles];
        const existingRoleIndex = updatedRoles.findIndex(r => r.name === role);

        if (existingRoleIndex !== -1) {
            updatedRoles[existingRoleIndex].count += 1;
        } else {
            updatedRoles.push({name: role, count: 1});
        }

        const sortedRoles = updatedRoles
            .sort((a, b) => b.count - a.count)
            .slice(0, 20);

        return updateSettingsWithOptimisticUI({recentRoles: sortedRoles});
    }, [settings, updateSettingsWithOptimisticUI]);

    const updateRecentTweaks = useCallback((tweak: string): Promise<void> => {
        const updatedTweaks = [tweak, ...settings.recentTweaks.filter(t => t !== tweak)].slice(0, 10);
        return updateSettingsWithOptimisticUI({recentTweaks: updatedTweaks});
    }, [settings, updateSettingsWithOptimisticUI]);

    const updateConversationFontSize = useCallback((recordingId: string, fontSize: number): Promise<void> => {
        const updatedFontSizes = {...settings.conversationFontSizes, [recordingId]: fontSize};
        return updateSettingsWithOptimisticUI({conversationFontSizes: updatedFontSizes});
    }, [settings, updateSettingsWithOptimisticUI]);

    const updateConversationLastLanguage = useCallback((recordingId: string, language: string): Promise<void> => {
        const updatedLastLanguages = {...settings.conversationLastLanguages, [recordingId]: language};
        return updateSettingsWithOptimisticUI({conversationLastLanguages: updatedLastLanguages});
    }, [settings, updateSettingsWithOptimisticUI]);

    const initializeSettings = useCallback(async (): Promise<void> => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await fetch('/api/user/settings');
            if (response.status === 401) {
                console.log('User not authenticated, skipping settings fetch');
                return;
            }
            if (!response.ok) throw new Error('Failed to fetch settings');
            const data = await response.json();
            setSettings(prevSettings => ({
                ...prevSettings,
                ...data,
                conversationFontSizes: {
                    ...prevSettings.conversationFontSizes,
                    ...data.conversationFontSizes
                },
                conversationLastLanguages: {
                    ...prevSettings.conversationLastLanguages,
                    ...data.conversationLastLanguages
                }
            }));
        } catch (err) {
            console.error('Error fetching user settings:', err);
            setError('Failed to load settings. Please try again later.');
            throw err;
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        initializeSettings();
    }, [initializeSettings]);

    const contextValue: SettingsContextType = {
        ...settings,
        isLoading,
        error,
        updateSettings,
        updateRecentLanguages,
        updateRecentRoles,
        incrementRoleUsage,
        updateRecentTweaks,
        updateConversationFontSize,
        updateConversationLastLanguage,
        initializeSettings,
    };

    return (
        <SettingsContext.Provider
            value={contextValue}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
};
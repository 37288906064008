'use client';

import React, { useEffect, useCallback, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/navigation';
import { isMobile } from "@/utils/deviceDetection";
import VisualViewportWrapper from './VisualViewportWrapper';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    title?: string;
    footer?: React.ReactNode;
    className?: string;
    closeOnEsc?: boolean;
    closeOnOutsideClick?: boolean;
    fullScreenOnMobile?: boolean;
    minHeight?: string;
    minWidth?: string;
}

const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    children,
    title,
    footer,
    className,
    closeOnEsc = true,
    closeOnOutsideClick = true,
    fullScreenOnMobile = true,
    minHeight,
    minWidth
}) => {
    const router = useRouter();
    const isMobileDevice = isMobile();
    const [modalHeight, setModalHeight] = useState('100%');

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape' && closeOnEsc) {
            onClose();
        }
    }, [onClose, closeOnEsc]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
            window.history.pushState(null, '', window.location.href);
            window.addEventListener('popstate', onClose);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('popstate', onClose);
        };
    }, [isOpen, handleKeyDown, onClose, router]);

    const handleViewportChange = useCallback((viewport: { width: number; height: number }) => {
        setModalHeight(`${viewport.height}px`);
    }, []);

    const modalClasses = isMobileDevice && fullScreenOnMobile
        ? 'fixed inset-0 m-0'
        : 'inline-block w-full max-w-[800px] my-8';

    const translationOptions: TranslationOptions = { source: 'uiComponents' };

    return (
        <AnimatePresence>
            {isOpen && (
                <Dialog
                    as={motion.div}
                    className={twMerge(
                        "fixed inset-0 z-50 overflow-y-auto"
                    )}
                    static
                    open={isOpen}
                    onClose={closeOnOutsideClick ? onClose : () => { }}
                >
                    <div className="min-h-screen px-4 flex items-center justify-center">
                        <motion.div
                            className="fixed inset-0 bg-black bg-opacity-50"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={() => closeOnOutsideClick && onClose()}
                            style={{ zIndex: 40 }}
                        />

                        <VisualViewportWrapper onViewportChange={handleViewportChange}>
                            <motion.div
                                className={twMerge(
                                    `relative p-2 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl flex flex-col z-50`,
                                    modalClasses,
                                    className
                                )}
                                initial={{ scale: 0.9, y: 20, opacity: 0 }}
                                animate={{ scale: 1, y: 0, opacity: 1 }}
                                exit={{ scale: 0.9, y: 20, opacity: 0 }}
                                style={{ 
                                    display: 'flex', 
                                    height: isMobileDevice && fullScreenOnMobile ? modalHeight : 'auto',
                                    minHeight: minHeight,
                                    minWidth: minWidth
                                }}
                            >
                                <button
                                    onClick={onClose}
                                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                                    aria-label={__("Close modal", translationOptions)}
                                >
                                    <FiX size={24} />
                                </button>
                                {title && (
                                    <h2 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 mb-4">
                                        {title}
                                    </h2>
                                )}
                                <div className="flex-grow flex flex-col overflow-auto">
                                    {children}
                                </div>
                                {footer && (
                                    <div className="mt-4">
                                        {footer}
                                    </div>
                                )}
                            </motion.div>
                        </VisualViewportWrapper>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};

export default Modal;
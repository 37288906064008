import React, { useState, useEffect } from 'react';
import { FiArchive, FiCheckSquare, FiEdit, FiTrash2 } from 'react-icons/fi';
import { Root, Trigger, Portal, Content } from '@radix-ui/react-popover';
import { Button } from "@/components/ui/button";
import { twMerge } from 'tailwind-merge';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface OptionsPopoverProps {
    onEdit: () => void;
    onArchive: () => void;
    onDelete: () => void;
    isArchived: boolean;
    onSelect: () => void;
    isSelectionMode: boolean;
    children: React.ReactNode;
}

const OptionsPopover: React.FC<OptionsPopoverProps> = ({
    onEdit,
    onArchive,
    onDelete,
    isArchived,
    onSelect,
    isSelectionMode,
    children
}) => {
    const [open, setOpen] = useState(false);
    const [confirmTimer, setConfirmTimer] = useState(3);
    const [isConfirming, setIsConfirming] = useState(false);

    const translationOptions: TranslationOptions = { source: 'uiComponents' };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isConfirming) {
            timer = setInterval(() => {
                setConfirmTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(timer);
                        setIsConfirming(false);
                        return 3;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isConfirming]);

    const handleOptionClick = (action: () => void) => {
        action();
        if (!isConfirming) {
            setOpen(false);
        }
    };

    const handleDelete = () => {
        if (isConfirming) {
            onDelete();
            setIsConfirming(false);
            setOpen(false);
        } else {
            setIsConfirming(true);
        }
    };

    return (
        <Root open={open} onOpenChange={setOpen}>
            <Trigger asChild>
                {children}
            </Trigger>
            <Portal>
                <Content className="w-48 p-0 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-lg rounded-md">
                    <div className="flex flex-col">
                        {!isSelectionMode && (
                            <Button onClick={() => handleOptionClick(onSelect)} variant="ghost"
                                    className={twMerge("justify-start hover:bg-gray-100 dark:hover:bg-gray-700")}>
                                <FiCheckSquare className="mr-2"/> {__('Select', translationOptions)}
                            </Button>
                        )}
                        <Button onClick={() => handleOptionClick(onEdit)} variant="ghost"
                                className={twMerge("justify-start hover:bg-gray-100 dark:hover:bg-gray-700")}>
                            <FiEdit className="mr-2"/> {__('Edit', translationOptions)}
                        </Button>
                        <Button onClick={() => handleOptionClick(onArchive)} variant="ghost"
                                className={twMerge("justify-start hover:bg-gray-100 dark:hover:bg-gray-700")}>
                            <FiArchive className="mr-2"/> {isArchived ? __('Unarchive', translationOptions) : __('Archive', translationOptions)}
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant="ghost"
                            className={twMerge(
                                "justify-start",
                                isConfirming
                                    ? "bg-red-600 text-white hover:bg-red-700"
                                    : "text-red-600 hover:bg-red-100 dark:hover:bg-red-900"
                            )}
                        >
                            {isConfirming ? (
                                __('Confirm Deletion ({{timer}})', { ...translationOptions, placeholders: { timer: confirmTimer } })
                            ) : (
                                <>
                                    <FiTrash2 className="mr-2"/> {__('Delete', translationOptions)}
                                </>
                            )}
                        </Button>
                    </div>
                </Content>
            </Portal>
        </Root>
    );
};

export default OptionsPopover;
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { isMobile } from '@/utils/deviceDetection';

interface VisualViewportWrapperProps {
  children: React.ReactNode;
  className?: string;
  onViewportChange?: (viewport: { width: number; height: number }) => void;
}

const VisualViewportWrapper: React.FC<VisualViewportWrapperProps> = ({ 
  children, 
  className,
  onViewportChange 
}) => {
  const [viewport, setViewport] = useState<{ width: number; height: number } | null>(null);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const initialViewportHeight = useRef<number | null>(null);

  const updateViewport = useCallback(() => {
    if (window.visualViewport && isMobile()) {
      const newViewport = {
        width: window.visualViewport.width,
        height: window.visualViewport.height
      };

      if (initialViewportHeight.current === null) {
        initialViewportHeight.current = newViewport.height;
      }

      const keyboardVisible = newViewport.height < initialViewportHeight.current;
      setIsKeyboardVisible(keyboardVisible);

      if (keyboardVisible) {
        setViewport(newViewport);
        if (onViewportChange) {
          onViewportChange(newViewport);
        }
      } else {
        setViewport(null);
        if (onViewportChange) {
          onViewportChange({ width: window.innerWidth, height: window.innerHeight });
        }
      }
    }
  }, [onViewportChange]);

  useEffect(() => {
    if (!window.visualViewport || !isMobile()) {
      return;
    }

    updateViewport(); // Initial update

    const visualViewport = window.visualViewport;
    visualViewport.addEventListener('resize', updateViewport);
    visualViewport.addEventListener('scroll', updateViewport);

    return () => {
      visualViewport.removeEventListener('resize', updateViewport);
      visualViewport.removeEventListener('scroll', updateViewport);
    };
  }, [updateViewport]);

  useEffect(() => {
    if (wrapperRef.current) {
      if (viewport && isKeyboardVisible) {
        wrapperRef.current.style.height = `${viewport.height}px`;
        wrapperRef.current.style.width = `${viewport.width}px`;
      } else {
        wrapperRef.current.style.height = '';
        wrapperRef.current.style.width = '';
        wrapperRef.current.style.maxHeight = '100%';
        wrapperRef.current.style.maxWidth = '100%';
      }
    }
  }, [viewport, isKeyboardVisible]);

  return (
    <div ref={wrapperRef} className={className} style={{ overflow: 'auto' }}>
      {children}
    </div>
  );
};

export default VisualViewportWrapper;
import React from 'react';
import { Button } from "@/components/ui/button";
import { IoCopyOutline, IoPlayOutline, IoShareOutline, IoCloseOutline } from 'react-icons/io5';
import { PiTranslateBold } from "react-icons/pi";
import { RiSparkling2Fill } from 'react-icons/ri';
import { MdShare } from 'react-icons/md';
import SharePopover from './SharePopover';
import { TranslateModal } from './TranslateModal';
import { Root as TooltipRoot, Provider as TooltipProvider, Trigger as TooltipTrigger, Portal as TooltipPortal, Content as TooltipContent } from '@radix-ui/react-tooltip';
import { AIRewritePopover } from './AIRewritePopover';
import { Loader2 } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import { isAppleDevice } from '@/utils/deviceDetection';
import { __, TranslationOptions } from '@/utils/translationUtils';
import { useAudioPlayer } from '@/contexts/AudioPlayerContext';
import { Note } from '@/lib/notes/Note';
import { toast } from 'react-hot-toast';

interface AudioControlsProps {
    onCopy: (e: React.MouseEvent) => void;
    onTranslate: (language: string) => Promise<void>;
    text: string;
    isTranslating: boolean;
    noteId?: string;
    showPlay?: boolean;
    showShare?: boolean;
    showCopy?: boolean;
    showTranslate?: boolean;
    onRewrite: (action: string, role?: string, tweakInstruction?: string) => Promise<void>;
    isRewriting: boolean;
    item: Note;
    className?: string;
    hasUnsavedChanges?: boolean;
    onSaveChanges?: () => Promise<void>;
}

export const AudioControls: React.FC<AudioControlsProps> = ({
    onCopy,
    onTranslate,
    text,
    isTranslating,
    noteId,
    showPlay = true,
    showShare = true,
    showCopy = true,
    showTranslate = true,
    onRewrite,
    isRewriting,
    item,
    className = '',
    hasUnsavedChanges = false,
    onSaveChanges,
}) => {
    const { isPlaying, currentNote, play, pause } = useAudioPlayer();
    const isCurrentlyPlaying = isPlaying && currentNote?.id === noteId;

    const handlePlayStop = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (isCurrentlyPlaying) {
            pause();
        } else {
            play(item, 0);
        }
    };

    const commonButtonClasses = "flex-1 p-2 rounded-full transition-colors duration-200 hover:bg-opacity-10 hover:bg-black dark:hover:bg-white dark:hover:bg-opacity-10";

    const IconPlay = IoPlayOutline;
    const IconShare = isAppleDevice() ? IoShareOutline : MdShare;
    const IconCopy = IoCopyOutline;
    const IconClose = IoCloseOutline;

    const translationOptions: TranslationOptions = { source: 'audio' };

    const renderTooltip = (content: string, children: React.ReactNode) => (
        <TooltipRoot>
            <TooltipTrigger asChild>{children}</TooltipTrigger>
            <TooltipPortal>
                <TooltipContent className="bg-gray-800 text-white px-2 py-1 rounded text-sm">
                    {content}
                </TooltipContent>
            </TooltipPortal>
        </TooltipRoot>
    );

    const handleOperationWithSave = async (operation: () => Promise<void>) => {
        if (hasUnsavedChanges && onSaveChanges) {
            try {
                await onSaveChanges();
            } catch (error) {
                console.error('Failed to save changes:', error);
                toast.error(__('Failed to save changes before operation'));
                return;
            }
        }
        await operation();
    };

    const handleTranslateWithSave = async (language: string) => {
        await handleOperationWithSave(() => onTranslate(language));
    };

    const handleRewriteWithSave = async (action: string, role?: string, tweakInstruction?: string) => {
        await handleOperationWithSave(() => onRewrite(action, role, tweakInstruction));
    };

    return (
        <>
            <TooltipProvider>
                <div className={`walkthrough-audiocontrols-container flex items-center justify-between space-x-1 ${className} audio-controls-container`}>
                    {showPlay && renderTooltip(isCurrentlyPlaying ? __('Stop', translationOptions) : __('Play', translationOptions),
                        <Button
                            onClick={handlePlayStop}
                            className={`walkthrough-audiocontrols-playstop ${commonButtonClasses} button-play-stop audio-control-play-stop`}
                            variant="link"
                            size="icon"
                            aria-label={isCurrentlyPlaying ? __('Stop', translationOptions) : __('Play', translationOptions)}
                        >
                            {isCurrentlyPlaying ? <IconClose className="h-4 w-4" /> : <IconPlay className="h-4 w-4" />}
                        </Button>
                    )}

                    {showShare && renderTooltip(__('Share', translationOptions),
                        <SharePopover
                            onShare={() => { }}
                            shareContent={text || __('No text available to share', translationOptions)}
                        >
                            <Button
                                className={`walkthrough-audiocontrols-share ${commonButtonClasses} button-share audio-control-share`}
                                variant="link"
                                size="icon"
                                aria-label={__('Share', translationOptions)}
                            >
                                <IconShare className="h-4 w-4" />
                            </Button>
                        </SharePopover>
                    )}

                    {showCopy && renderTooltip(__('Copy', translationOptions),
                        <Button
                            onClick={onCopy}
                            className={`walkthrough-audiocontrols-copy ${commonButtonClasses} button-copy audio-control-copy`}
                            variant="link"
                            size="icon"
                            aria-label={__('Copy', translationOptions)}
                        >
                            <IconCopy className="h-4 w-4" />
                        </Button>
                    )}

                    {showTranslate && renderTooltip(__('Translate', translationOptions),
                        <TranslateModal
                            onTranslate={handleTranslateWithSave}
                            className={twMerge(`walkthrough-audiocontrols-translate ${commonButtonClasses}`, 'p-0 flex flex-row items-center button-translate audio-control-translate')}
                            buttonClassName={twMerge(commonButtonClasses, 'w-full h-full')}
                        />
                    )}

                    {renderTooltip(__('Rewrite', translationOptions),
                        <AIRewritePopover
                            onRewrite={handleRewriteWithSave}
                            isRewriting={isRewriting}
                            item={item}
                        >
                            <Button
                                className={`walkthrough-audiocontrols-rewrite ${commonButtonClasses} button-rewrite audio-control-rewrite`}
                                variant="link"
                                size="icon"
                                disabled={isRewriting}
                                aria-label={__('Rewrite', translationOptions)}
                            >
                                {isRewriting ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                ) : (
                                    <RiSparkling2Fill className="h-4 w-4" />
                                )}
                            </Button>
                        </AIRewritePopover>
                    )}
                </div>
            </TooltipProvider>
        </>
    );
};

import { __ } from '../utils/translationUtils';

export type LanguageCode = keyof typeof languageMap;

export const languageMap = {
    "en": __("English", { source: 'languages' }),
    "zh": __("Chinese", { source: 'languages' }),
    "de": __("German", { source: 'languages' }),
    "es": __("Spanish", { source: 'languages' }),
    "ru": __("Russian", { source: 'languages' }),
    "ko": __("Korean", { source: 'languages' }),
    "fr": __("French", { source: 'languages' }),
    "ja": __("Japanese", { source: 'languages' }),
    "pt": __("Portuguese", { source: 'languages' }),
    "tr": __("Turkish", { source: 'languages' }),
    "pl": __("Polish", { source: 'languages' }),
    "ca": __("Catalan", { source: 'languages' }),
    "nl": __("Dutch", { source: 'languages' }),
    "ar": __("Arabic", { source: 'languages' }),
    "sv": __("Swedish", { source: 'languages' }),
    "it": __("Italian", { source: 'languages' }),
    "id": __("Indonesian", { source: 'languages' }),
    "hi": __("Hindi", { source: 'languages' }),
    "fi": __("Finnish", { source: 'languages' }),
    "vi": __("Vietnamese", { source: 'languages' }),
    "he": __("Hebrew", { source: 'languages' }),
    "uk": __("Ukrainian", { source: 'languages' }),
    "el": __("Greek", { source: 'languages' }),
    "ms": __("Malay", { source: 'languages' }),
    "cs": __("Czech", { source: 'languages' }),
    "ro": __("Romanian", { source: 'languages' }),
    "da": __("Danish", { source: 'languages' }),
    "hu": __("Hungarian", { source: 'languages' }),
    "ta": __("Tamil", { source: 'languages' }),
    "no": __("Norwegian", { source: 'languages' }),
    "th": __("Thai", { source: 'languages' }),
    "ur": __("Urdu", { source: 'languages' }),
    "hr": __("Croatian", { source: 'languages' }),
    "bg": __("Bulgarian", { source: 'languages' }),
    "lt": __("Lithuanian", { source: 'languages' }),
    "la": __("Latin", { source: 'languages' }),
    "mi": __("Maori", { source: 'languages' }),
    "ml": __("Malayalam", { source: 'languages' }),
    "cy": __("Welsh", { source: 'languages' }),
    "sk": __("Slovak", { source: 'languages' }),
    "te": __("Telugu", { source: 'languages' }),
    "fa": __("Persian", { source: 'languages' }),
    "lv": __("Latvian", { source: 'languages' }),
    "bn": __("Bengali", { source: 'languages' }),
    "sr": __("Serbian", { source: 'languages' }),
    "az": __("Azerbaijani", { source: 'languages' }),
    "sl": __("Slovenian", { source: 'languages' }),
    "kn": __("Kannada", { source: 'languages' }),
    "et": __("Estonian", { source: 'languages' }),
    "mk": __("Macedonian", { source: 'languages' }),
    "br": __("Breton", { source: 'languages' }),
    "eu": __("Basque", { source: 'languages' }),
    "is": __("Icelandic", { source: 'languages' }),
    "hy": __("Armenian", { source: 'languages' }),
    "ne": __("Nepali", { source: 'languages' }),
    "mn": __("Mongolian", { source: 'languages' }),
    "bs": __("Bosnian", { source: 'languages' }),
    "kk": __("Kazakh", { source: 'languages' }),
    "sq": __("Albanian", { source: 'languages' }),
    "sw": __("Swahili", { source: 'languages' }),
    "gl": __("Galician", { source: 'languages' }),
    "mr": __("Marathi", { source: 'languages' }),
    "pa": __("Punjabi", { source: 'languages' }),
    "si": __("Sinhala", { source: 'languages' }),
    "km": __("Khmer", { source: 'languages' }),
    "sn": __("Shona", { source: 'languages' }),
    "yo": __("Yoruba", { source: 'languages' }),
    "so": __("Somali", { source: 'languages' }),
    "af": __("Afrikaans", { source: 'languages' }),
    "oc": __("Occitan", { source: 'languages' }),
    "ka": __("Georgian", { source: 'languages' }),
    "be": __("Belarusian", { source: 'languages' }),
    "tg": __("Tajik", { source: 'languages' }),
    "sd": __("Sindhi", { source: 'languages' }),
    "gu": __("Gujarati", { source: 'languages' }),
    "am": __("Amharic", { source: 'languages' }),
    "yi": __("Yiddish", { source: 'languages' }),
    "lo": __("Lao", { source: 'languages' }),
    "uz": __("Uzbek", { source: 'languages' }),
    "fo": __("Faroese", { source: 'languages' }),
    "ht": __("Haitian Creole", { source: 'languages' }),
    "ps": __("Pashto", { source: 'languages' }),
    "tk": __("Turkmen", { source: 'languages' }),
    "nn": __("Nynorsk", { source: 'languages' }),
    "mt": __("Maltese", { source: 'languages' }),
    "sa": __("Sanskrit", { source: 'languages' }),
    "lb": __("Luxembourgish", { source: 'languages' }),
    "my": __("Myanmar", { source: 'languages' }),
    "bo": __("Tibetan", { source: 'languages' }),
    "tl": __("Tagalog", { source: 'languages' }),
    "mg": __("Malagasy", { source: 'languages' }),
    "as": __("Assamese", { source: 'languages' }),
    "tt": __("Tatar", { source: 'languages' }),
    "haw": __("Hawaiian", { source: 'languages' }),
    "ln": __("Lingala", { source: 'languages' }),
    "ha": __("Hausa", { source: 'languages' }),
    "ba": __("Bashkir", { source: 'languages' }),
    "jw": __("Javanese", { source: 'languages' }),
    "su": __("Sundanese", { source: 'languages' }),
    "yue": __("Cantonese", { source: 'languages' }),
} as const;

export const getLanguageOptions = () =>
    Object.entries(languageMap).map(([value, label]) => ({ value, label }));

export const getRecentLanguageOptions = (recentLanguages: LanguageCode[]) =>
    recentLanguages.map(lang => ({
        value: lang,
        label: languageMap[lang] || lang
    }));
import { useCallback, useRef } from 'react';

interface LongPressOptions {
  onLongPress: () => void;
  onClick: (event?: React.MouseEvent) => void;
  threshold?: number;
}

export const useLongPress = ({ onLongPress, onClick, threshold = 600 }: LongPressOptions) => {
  const longPressTriggered = useRef(false);
  const timeout = useRef<NodeJS.Timeout>();
  const target = useRef<EventTarget>();
  const startPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const scrollThreshold = 30; // Pixels of movement before canceling long press

  const start = useCallback(
    (event: React.TouchEvent | React.MouseEvent) => {
      if (event.target) {
        target.current = event.target;
        
        // Record starting position
        if ('touches' in event) {
          startPos.current = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY
          };
        } else {
          startPos.current = {
            x: event.clientX,
            y: event.clientY
          };
        }

        timeout.current = setTimeout(() => {
          onLongPress();
          longPressTriggered.current = true;
        }, threshold);
      }
    },
    [onLongPress, threshold]
  );

  const move = useCallback(
    (event: React.TouchEvent | React.MouseEvent) => {
      if (!target.current) return;

      const currentPos = 'touches' in event 
        ? { x: event.touches[0].clientX, y: event.touches[0].clientY }
        : { x: event.clientX, y: event.clientY };

      const deltaX = Math.abs(currentPos.x - startPos.current.x);
      const deltaY = Math.abs(currentPos.y - startPos.current.y);

      // Cancel long press if moved beyond threshold
      if (deltaX > scrollThreshold || deltaY > scrollThreshold) {
        clearTimeout(timeout.current!);
        target.current = undefined;
        longPressTriggered.current = false;
      }
    },
    []
  );

  const clear = useCallback(
    (event: React.TouchEvent | React.MouseEvent, shouldTriggerClick = true) => {
      clearTimeout(timeout.current!);
      if (shouldTriggerClick && !longPressTriggered.current && target.current) {
        if ('button' in event) {
          onClick(event as React.MouseEvent);
        } else {
          onClick();
        }
      }
      longPressTriggered.current = false;
      target.current = undefined;
    },
    [onClick]
  );

  return {
    onMouseDown: (e: React.MouseEvent) => start(e),
    onTouchStart: (e: React.TouchEvent) => start(e),
    onMouseMove: (e: React.MouseEvent) => move(e),
    onTouchMove: (e: React.TouchEvent) => move(e),
    onMouseUp: (e: React.MouseEvent) => clear(e),
    onMouseLeave: (e: React.MouseEvent) => clear(e, false),
    onTouchEnd: (e: React.TouchEvent) => clear(e),
  };
};

import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface VersionArrowsProps {
    currentVersionIndex: number;
    totalVersions: number;
    onPrevious: () => void;
    onNext: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const VersionArrows: React.FC<VersionArrowsProps> = ({
    currentVersionIndex,
    totalVersions,
    onPrevious,
    onNext,
    className = '',
    style
}) => {
    const currentVersion = totalVersions - currentVersionIndex;

    return (
        <div
            className={`walkthrough-versionarrows-container version-arrows flex items-stretch bg-gray-100 bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-80 rounded-full text-sm ${className}`}
            onClick={(e) => e.stopPropagation()}
            style={style}
        >
            <button
                className={`walkthrough-versionarrows-previous version-arrows-previous flex-1 flex items-center justify-center py-2 px-3 rounded-l-full transition-colors ${currentVersion === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200 hover:bg-opacity-50 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:bg-opacity-50'}`}
                onClick={() => currentVersion !== 1 && onPrevious()}
                disabled={currentVersion === 1}
                aria-label="Previous version"
            >
                <FiChevronLeft size={16} />
                <span className="walkthrough-versionarrows-previous-number ml-1">{currentVersion}</span>
            </button>
            <div className="walkthrough-versionarrows-separator flex items-center justify-center px-0 text-gray-500 dark:text-gray-400">
                /
            </div>
            <button
                className={`walkthrough-versionarrows-next version-arrows-next flex-1 flex items-center justify-center py-2 px-3 rounded-r-full transition-colors ${currentVersion === totalVersions ? 'text-gray-400 cursor-not-allowed' : 'text-gray-600 hover:bg-gray-200 hover:bg-opacity-50 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:bg-opacity-50'}`}
                onClick={() => currentVersion !== totalVersions && onNext()}
                disabled={currentVersion === totalVersions}
                aria-label="Next version"
            >
                <span className="walkthrough-versionarrows-next-number mr-1">{totalVersions}</span>
                <FiChevronRight size={16} />
            </button>
        </div>
    );
};

export default VersionArrows;
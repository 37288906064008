import React from 'react';
import { Button } from "@/components/ui/button";

interface PopoverButtonProps {
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
}

export const PopoverButton: React.FC<PopoverButtonProps> = ({ icon, label, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="ghost"
      className="w-full justify-start hover:bg-gray-100 dark:hover:bg-gray-700"
    >
      {icon && <span className="mr-2">{icon}</span>}
      {label}
    </Button>
  );
};
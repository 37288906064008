export const isMobile = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth <= 768;
  }
  return false;
};

export const isAppleDevice = () => {
  return /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent);
};

export const isIOS = (): boolean => {
  if (typeof window !== 'undefined' && window.navigator) {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent) && !('MSStream' in window);
  }
  return false;
};
    import React, { forwardRef, useCallback, useEffect, useState, useRef } from 'react';
    import { createPortal } from 'react-dom';
    import { Button } from "@/components/ui/button";
    import { PiTranslateBold } from "react-icons/pi";
    import LoadingSpinner from './LoadingSpinner';
    import { useSettings } from '@/contexts/SettingsContext';
    import { useTheme } from 'next-themes';
    import { Loader2, Search } from 'lucide-react';
    import { useMediaQuery } from 'react-responsive';
    import { languageOptions } from "@/components/LanguageOptions";
    import { __ } from '@/utils/translationUtils';

    interface TranslateModalProps {
        onTranslate: (language: string) => Promise<void>;
        children?: React.ReactNode;
        className?: string;
        buttonClassName?: string;
    }

    export const TranslateModal = forwardRef<HTMLButtonElement, TranslateModalProps>(({ onTranslate, children, className, buttonClassName }, ref) => {
        const [isOpen, setIsOpen] = useState(false);
        const [isTranslating, setIsTranslating] = useState(false);
        const [searchTerm, setSearchTerm] = useState('');
        const { recentLanguages, updateRecentLanguages } = useSettings();
        const { theme } = useTheme();
        const isMobile = useMediaQuery({ maxWidth: 768 });
        const searchInputRef = useRef<HTMLInputElement>(null);

        const handleTranslate = async (language: string) => {
            setIsTranslating(true);
            try {
                await onTranslate(language);
                updateRecentLanguages(language);
            } finally {
                setIsTranslating(false);
                setIsOpen(false);
            }
        };

        const closeModal = useCallback(() => {
            setIsOpen(false);
            setSearchTerm('');
        }, []);

        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    closeModal();
                }
            };

            if (isOpen) {
                document.addEventListener('keydown', handleEscKey);
                if (searchInputRef.current) {
                    searchInputRef.current.focus();
                }
            }

            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [isOpen, closeModal, isMobile]);

        const filteredLanguages = languageOptions.filter(lang =>
            lang.label.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const recentLanguageOptions = recentLanguages
            .map(lang => languageOptions.find(option => option.value === lang))
            .filter((lang): lang is typeof languageOptions[0] => lang !== undefined);

        const renderLanguageList = () => {
            const allLanguages = [
                ...recentLanguageOptions.map(lang => ({ ...lang, isRecent: true })),
                ...filteredLanguages.filter(lang => !recentLanguageOptions.some(recent => recent.value === lang.value))
            ];

            return (
                <ul className="walkthrough-translatemodal-languagelist overflow-y-auto">
                    {allLanguages.map((lang, index) => (
                        <React.Fragment key={lang.value}>
                            {index === recentLanguageOptions.length && searchTerm === '' && (
                                <li className="walkthrough-translatemodal-alllanguages px-4 py-2 font-medium text-sm text-gray-500 dark:text-gray-400">
                                    {__("All Languages", { source: 'ui' })}
                                </li>
                            )}
                            <li
                                className="walkthrough-translatemodal-languageitem px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-150 ease-in-out flex justify-between items-center"
                                onClick={() => handleTranslate(lang.value)}
                            >
                                <span>{lang.label}</span>
                                {('isRecent' in lang && lang.isRecent) ? (
                                    <span className="walkthrough-translatemodal-recentlabel text-xs text-gray-500 dark:text-gray-400">{__("Recent", { source: 'ui' })}</span>
                                ) : null}
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            );
        };

        const renderModal = () => {
            if (!isOpen) return null;

            return createPortal(
                <div
                    className="walkthrough-translatemodal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50 pt-4 md:items-center md:pt-0"
                    onClick={closeModal}
                >
                    <div
                        className={`walkthrough-translatemodal-content translate-modal-content bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-sm ${isMobile ? 'mx-4' : ''}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="walkthrough-translatemodal-title text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">{__("Translate", { source: 'ui' })}</h2>
                        <div className="walkthrough-translatemodal-searchcontainer relative mb-4">
                            <input
                                type="text"
                                placeholder={__("Search languages", { source: 'ui' })}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="walkthrough-translatemodal-searchinput w-full pl-10 pr-4 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                ref={searchInputRef}
                            />
                            <Search className="walkthrough-translatemodal-searchicon absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                        </div>
                        <div className="walkthrough-translatemodal-languagecontainer max-h-60 overflow-y-auto">
                            {recentLanguageOptions.length > 0 && searchTerm === '' && (
                                <h3 className="walkthrough-translatemodal-recentheader px-4 py-2 font-medium text-sm text-gray-500 dark:text-gray-400">{__("Recent", { source: 'ui' })}</h3>
                            )}
                            {renderLanguageList()}
                        </div>
                        {isTranslating && (
                            <div className="walkthrough-translatemodal-loading flex items-center justify-center mt-4">
                                <LoadingSpinner className="mr-2 h-4 w-4" />
                                <span>{__("Translating...", { source: 'ui' })}</span>
                            </div>
                        )}
                    </div>
                </div>,
                document.body
            );
        };

        return (
            <div className={`walkthrough-translatemodal-container ${className}`}>
                <Button
                    onClick={() => setIsOpen(true)}
                    className={`walkthrough-translatemodal-button ${buttonClassName}`}
                    variant="link"
                    size="icon"
                    ref={ref}
                >
                    {isTranslating ? (
                        <Loader2 className="walkthrough-translatemodal-loadingicon h-4 w-4 animate-spin"/>
                    ) : (
                        <PiTranslateBold className="walkthrough-translatemodal-translateicon h-4 w-4"/>
                    )}
                </Button>
                {renderModal()}
            </div>
        );
    });

    TranslateModal.displayName = 'TranslateModal';
import { useState, useCallback } from 'react';
import { useCredits } from '@/contexts/CreditsContext';
import { showToast } from '@/utils/toast';
import { Note } from '@/lib/notes/Note';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface RewriteOptions {
  id: string;
  action: string;
  role?: string;
  text?: string;
  versionId?: string;
  tweakInstruction?: string;
}

export const useRewrite = (onUpdate?: (item: Note) => void) => {
  const [isRewriting, setIsRewriting] = useState(false);
  const { fetchCredits } = useCredits();
  const [insufficientCreditsError, setInsufficientCreditsError] = useState(false);

  const handleRewrite = useCallback(async ({ id, action, role, text, versionId, tweakInstruction }: RewriteOptions) => {
    const translationOptions: TranslationOptions = { source: 'core' };

    if (!id) {
      console.error(__('No item ID available', translationOptions));
      return;
    }

    setIsRewriting(true);
    try {
      const response = await fetch('/api/ai-rewrite', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, action, role, text, versionId, tweakInstruction }),
      });

      if (!response.ok) {
        if (response.status === 402) {
          setInsufficientCreditsError(true);
          return;
        }
        throw new Error(__('Rewrite failed: {{status}} {{statusText}}', {
          ...translationOptions,
          placeholders: { status: response.status, statusText: response.statusText }
        }));
      }

      const updatedItem: Note = await response.json();

      if (!updatedItem || !updatedItem.id) {
        throw new Error(__('Invalid response from rewrite service', translationOptions));
      }

      onUpdate?.(updatedItem);
      showToast(__('Text rewritten successfully', { source: 'uiComponents' }), 'success');
      fetchCredits();

      return updatedItem;
    } catch (error) {
      console.error(__('Rewrite error:', translationOptions), error);
      showToast(__('Failed to rewrite text: {{errorMessage}}', {
        ...translationOptions,
        placeholders: { errorMessage: error instanceof Error ? error.message : __('Unknown error', translationOptions) }
      }), 'error');
    } finally {
      setIsRewriting(false);
    }
  }, [onUpdate, fetchCredits]);

  return {
    isRewriting,
    handleRewrite,
    insufficientCreditsError,
  };
};
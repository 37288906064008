import React, { forwardRef, useEffect, useState } from 'react';
import { Root as PopoverRoot, Trigger as PopoverTrigger, Portal as PopoverPortal, Content as PopoverContent } from '@radix-ui/react-popover';
import { Button } from "@/components/ui/button";
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, SingleValue } from 'react-select';
import { useSettings } from '@/contexts/SettingsContext';
import { useTheme } from 'next-themes';
import {
    FaBrain,
    FaListUl,
    FaMagic,
    FaRedo,
    FaRegCommentDots,
    FaSpellCheck,
    FaUserCircle,
    FaWrench
} from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { RewriteAction } from "@/lib/services/rewriteAction";
import { Provider as TooltipProvider, Root as TooltipRoot, Trigger as TooltipTrigger, Content as TooltipContent, Arrow as TooltipArrow } from '@radix-ui/react-tooltip';
import { __, translationSources } from '@/utils/translationUtils';

interface AIRewritePopoverProps {
    onRewrite: (action: RewriteAction, role?: string, tweakInstruction?: string) => Promise<void>;
    isRewriting: boolean;
    item: any;
    children: React.ReactNode;
}

interface RoleOption {
    value: string;
    label: string;
    count: number;
}

const defaultRoles: RoleOption[] = [
    { value: 'Linus Torvalds (blunt dev)', label: __('Linus Torvalds (blunt dev)', { source: translationSources.core }), count: 0 },
    { value: 'Paul Graham (essay writer)', label: __('Paul Graham (essay writer)', { source: translationSources.core }), count: 0 },
    { value: 'Pieter Levels (indie hacker)', label: __('Pieter Levels (indie hacker)', { source: translationSources.core }), count: 0 },
    { value: 'Elon Musk (tech visionary)', label: __('Elon Musk (tech visionary)', { source: translationSources.core }), count: 0 },
    { value: 'Naval Ravikant (aphorist)', label: __('Naval Ravikant (aphorist)', { source: translationSources.core }), count: 0 },
    { value: 'Richard Feynman (explainer)', label: __('Richard Feynman (explainer)', { source: translationSources.core }), count: 0 },
    { value: 'Hunter S. Thompson (gonzo)', label: __('Hunter S. Thompson (gonzo)', { source: translationSources.core }), count: 0 },
    { value: 'David Foster Wallace (verbose)', label: __('David Foster Wallace (verbose)', { source: translationSources.core }), count: 0 },
    { value: 'Nassim Taleb (contrarian)', label: __('Nassim Taleb (contrarian)', { source: translationSources.core }), count: 0 },
    { value: 'Werner Herzog (philosophical)', label: __('Werner Herzog (philosophical)', { source: translationSources.core }), count: 0 },
    { value: 'Slavoj Žižek (dialectical)', label: __('Slavoj Žižek (dialectical)', { source: translationSources.core }), count: 0 },
    { value: 'Christopher Hitchens (polemicist)', label: __('Christopher Hitchens (polemicist)', { source: translationSources.core }), count: 0 },
    { value: 'Douglas Adams (absurdist)', label: __('Douglas Adams (absurdist)', { source: translationSources.core }), count: 0 },
    { value: 'George Carlin (satirist)', label: __('George Carlin (satirist)', { source: translationSources.core }), count: 0 },
    { value: 'Carl Sagan (cosmic perspective)', label: __('Carl Sagan (cosmic perspective)', { source: translationSources.core }), count: 0 },
    { value: 'Steve Wozniak (tech enthusiast)', label: __('Steve Wozniak (tech enthusiast)', { source: translationSources.core }), count: 0 },
];

const rewriteOptions = [
    { label: __('Fix Syntax', { source: translationSources.core }), action: RewriteAction.FIX_SYNTAX, icon: FaSpellCheck, strength: 1 },
    { label: __('Fix Terms', { source: translationSources.core }), action: RewriteAction.FIX_TERMS, icon: FaBrain, strength: 2 },
    { label: __('Fix Format', { source: translationSources.core }), action: RewriteAction.FIX_FORMAT, icon: FaMagic, strength: 3 },
    { label: __('Rewrite', { source: translationSources.core }), action: RewriteAction.REWRITE, icon: FaRedo, strength: 4 },
    { label: __('Clear Message', { source: translationSources.core }), action: RewriteAction.CLEAR_MESSAGE, icon: FaRegCommentDots, strength: 4 },
    { label: __('Structured Text', { source: translationSources.core }), action: RewriteAction.STRUCTURED_TEXT, icon: FaListUl, strength: 5 },
    { label: __('Rewrite As +', { source: translationSources.core }), action: RewriteAction.REWRITE, icon: FaUserCircle, strength: 5, isCustom: true },
    { label: __('Custom Tweak +', { source: translationSources.core }), action: RewriteAction.TWEAK, icon: FaWrench, strength: 3, isCustom: true },
];
const StrengthIndicator = ({ strength, className }: { strength: number; className?: string }) => {
    const percentage = (strength / 5) * 100;
    const color = `hsl(${120 - (strength - 1) * 30}, 100%, 50%)`;

    return (
        <div className={`walkthrough-airewritepopover-strengthindicator relative w-5 h-5 mr-2 ${className || ''}`} title={__('Strength: {{strength}}/5', { placeholders: { strength } })}>
            <svg viewBox="0 0 36 36" className="w-full h-full">
                <path
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#e6e6e6"
                    strokeWidth="4"
                />
                <path
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke={color}
                    strokeWidth="4"
                    strokeDasharray={`${percentage}, 100`}
                />
            </svg>
            <FaBrain
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white dark:text-gray-300 w-3 h-3" />
        </div>
    );
};

export const AIRewritePopover = forwardRef<HTMLDivElement, AIRewritePopoverProps>(
    ({ onRewrite, isRewriting, item, children }, ref) => {
        const [isOpen, setIsOpen] = useState(false);
        const [selectedRole, setSelectedRole] = useState<RoleOption | null>(null);
        const { recentRoles, updateRecentRoles, incrementRoleUsage, recentTweaks, updateRecentTweaks } = useSettings();
        const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);
        const { theme } = useTheme();
        const isMobile = useMediaQuery({ maxWidth: 768 });
        const [showRewriteAs, setShowRewriteAs] = useState(false);
        const [tweakInstruction, setTweakInstruction] = useState('');
        const [showTweakInput, setShowTweakInput] = useState(false);

        useEffect(() => {
            const sortedRecentRoles = recentRoles
                .sort((a, b) => b.count - a.count)
                .slice(0, 5)
                .map(role => ({ value: role.name, label: role.name, count: role.count }));

            const filteredDefaultRoles = defaultRoles.filter(
                defaultRole => !sortedRecentRoles.some(
                    recentRole => recentRole.value === defaultRole.value
                )
            );

            setRoleOptions([...sortedRecentRoles, ...filteredDefaultRoles]);
        }, [recentRoles]);

        const handleRoleChange = (newValue: SingleValue<RoleOption>, actionMeta: ActionMeta<RoleOption>) => {
            setSelectedRole(newValue);
        };

        const handleRoleSelect = (role: RoleOption) => {
            setSelectedRole(role);
            updateRecentRoles(role.value);
        };

        const isValidNewOption = (inputValue: string) => {
            return inputValue.length <= 50;
        };

        const customStyles = {
            control: (provided: any) => ({
                ...provided,
                backgroundColor: '#1f2937',
                borderColor: '#374151',
                '&:hover': {
                    borderColor: '#4b5563',
                },
                fontSize: '0.875rem',
                minHeight: '32px',
            }),
            menu: (provided: any) => ({
                ...provided,
                backgroundColor: '#1f2937',
                fontSize: '0.875rem',
            }),
            option: (provided: any, state: {
                isFocused: boolean;
                isSelected: boolean;
            }) => ({
                ...provided,
                backgroundColor: state.isFocused
                    ? '#374151'
                    : state.isSelected
                        ? '#4b5563'
                        : 'transparent',
                color: '#f3f4f6',
                '&:active': {
                    backgroundColor: '#4b5563',
                },
                fontSize: '0.875rem',
                padding: '4px 8px',
            }),
            singleValue: (provided: any) => ({
                ...provided,
                color: '#f3f4f6',
                fontSize: '0.875rem',
            }),
            input: (provided: any) => ({
                ...provided,
                color: '#f3f4f6',
                fontSize: '0.875rem',
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                padding: '0 6px',
            }),
        };

        const handleTweakSelect = (tweak: string) => {
            setTweakInstruction(tweak);
        };

        const handleRewrite = async (action: RewriteAction, role?: string, tweakInstruction?: string) => {
            setIsOpen(false);
            if (role) {
                incrementRoleUsage(role);
            }
            if (action === RewriteAction.TWEAK && tweakInstruction) {
                updateRecentTweaks(tweakInstruction);
            }
            await onRewrite(action, role, tweakInstruction);
            // Reset after use
            setTweakInstruction('');
            setShowTweakInput(false);
            setSelectedRole(null);
            setShowRewriteAs(false);
        };

        const handleOpenChange = (open: boolean) => {
            if (!open) {
                const activeElement = document.activeElement as HTMLElement;
                if (activeElement) {
                    const classes = activeElement.className.split(' ');
                    const parentClasses = Array.from(activeElement.parentElement?.classList || []);
                    const allClasses = [...classes, ...parentClasses];
                    if (allClasses.some(cls => cls === 'do-not-close-popover')) {
                        return;
                    }
                }
            }
            setIsOpen(open);
        };

        return (
            <PopoverRoot open={isOpen} onOpenChange={handleOpenChange}>
                <PopoverTrigger asChild>{children}</PopoverTrigger>
                <PopoverPortal>
                    <PopoverContent
                        ref={ref}
                        className="walkthrough-airewritepopover-content w-64 max-w-[90vw] bg-white dark:bg-gray-800 rounded-lg shadow-xl p-2 sm:p-3 z-[60] text-xs sm:text-sm"
                        side="bottom"
                        align="start"
                        sideOffset={5}
                    >
                        <div className="walkthrough-airewritepopover-options flex flex-col space-y-1 sm:space-y-2">
                            {rewriteOptions.map((option) => (
                                <React.Fragment key={option.label}>
                                    {option.action === RewriteAction.TWEAK ? (
                                        <div className="space-y-1 sm:space-y-2">
                                            <Button
                                                onClick={() => setShowTweakInput(!showTweakInput)}
                                                className="walkthrough-airewritepopover-tweakbutton w-full justify-start bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 text-white font-medium py-1 sm:py-2 px-2 sm:px-3 rounded-md transition-all duration-200 ai-rewrite-tweak-button flex items-center text-xs sm:text-sm do-not-close-popover"
                                                disabled={isRewriting}
                                                size="xs"
                                            >
                                                <option.icon className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                                                <span>{option.label}</span>
                                                <StrengthIndicator strength={option.strength} className="ml-auto" />
                                            </Button>
                                            {showTweakInput && (
                                                <div className="space-y-1 sm:space-y-2 do-not-close-popover">
                                                    <input
                                                        type="text"
                                                        value={tweakInstruction}
                                                        onChange={(e) => setTweakInstruction(e.target.value)}
                                                        className="walkthrough-airewritepopover-tweakinput w-full px-2 sm:px-3 py-1 sm:py-2 text-xs sm:text-sm bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md ai-rewrite-tweak-input"
                                                        placeholder={__('Enter tweak instruction...')}
                                                    />
                                                    {recentTweaks.length > 0 && (
                                                        <div className="max-h-16 sm:max-h-24 overflow-y-auto">
                                                            <label className="block text-2xs sm:text-xs font-medium mb-1 text-gray-700 dark:text-gray-300">
                                                                {__('Recent Tweaks:')}
                                                            </label>
                                                            <div className="flex flex-wrap gap-1">
                                                                {recentTweaks.map((tweak, index) => (
                                                                    <TooltipProvider key={index}>
                                                                        <TooltipRoot>
                                                                            <TooltipTrigger asChild>
                                                                                <button
                                                                                    onClick={() => handleTweakSelect(tweak)}
                                                                                    className="walkthrough-airewritepopover-recenttweakbutton text-2xs sm:text-xs bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-1 sm:px-2 py-0.5 sm:py-1 rounded-full truncate max-w-full ai-rewrite-recent-tweak-button do-not-close-popover"
                                                                                >
                                                                                    {tweak}
                                                                                </button>
                                                                            </TooltipTrigger>
                                                                            <TooltipContent
                                                                                className="walkthrough-airewritepopover-tweaktooltip bg-gray-800 text-white p-1 sm:p-2 rounded-md text-2xs sm:text-xs max-w-xs"
                                                                                side="top"
                                                                                sideOffset={5}
                                                                            >
                                                                                {tweak}
                                                                                <TooltipArrow className="fill-gray-800" />
                                                                            </TooltipContent>
                                                                        </TooltipRoot>
                                                                    </TooltipProvider>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Button
                                                        onClick={() => handleRewrite(RewriteAction.TWEAK, undefined, tweakInstruction)}
                                                        className="walkthrough-airewritepopover-applytweak w-full justify-center bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 text-white font-medium py-1 sm:py-2 px-2 sm:px-3 rounded-md transition-all duration-200 ai-rewrite-apply-tweak-button text-xs sm:text-sm"
                                                        disabled={isRewriting || !tweakInstruction}
                                                        size="xs"
                                                    >
                                                        {isRewriting ? __('Tweaking...') : __('Apply Tweak')}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    ) : option.isCustom ? (
                                        <div className="space-y-1 sm:space-y-2">
                                            <Button
                                                onClick={() => setShowRewriteAs(!showRewriteAs)}
                                                className="walkthrough-airewritepopover-rewriteasbutton w-full justify-start bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-medium py-1 sm:py-2 px-2 sm:px-3 rounded-md transition-all duration-200 ai-rewrite-as-button flex items-center text-xs sm:text-sm do-not-close-popover"
                                                disabled={isRewriting}
                                                size="xs"
                                            >
                                                <option.icon className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                                                <span>{option.label}</span>
                                                <StrengthIndicator strength={option.strength} className="ml-auto" />
                                            </Button>
                                            {showRewriteAs && (
                                                <div className="space-y-1 sm:space-y-2 do-not-close-popover">
                                                    <CreatableSelect<RoleOption>
                                                        options={roleOptions}
                                                        value={selectedRole}
                                                        onChange={handleRoleChange}
                                                        onCreateOption={(inputValue) => {
                                                            const newOption = {
                                                                value: inputValue,
                                                                label: inputValue,
                                                                count: 0
                                                            };
                                                            handleRoleSelect(newOption);
                                                        }}
                                                        isDisabled={isRewriting}
                                                        placeholder={__('Select or type a role...')}
                                                        isClearable
                                                        formatCreateLabel={(inputValue) => __('Use "{{inputValue}}"', { placeholders: { inputValue } })}
                                                        isValidNewOption={isValidNewOption}
                                                        noOptionsMessage={({ inputValue }) =>
                                                            inputValue.length > 50 ? __("Role name must be 50 characters or less") : __("No options")
                                                        }
                                                        className="walkthrough-airewritepopover-roleselect text-xs sm:text-sm ai-rewrite-role-select"
                                                        maxMenuHeight={120}
                                                        styles={customStyles}
                                                        autoFocus={true}
                                                    />
                                                    {recentRoles.length > 0 && (
                                                        <div className="max-h-16 sm:max-h-24 overflow-y-auto">
                                                            <label className="block text-2xs sm:text-xs font-medium mb-1 text-gray-700 dark:text-gray-300">
                                                                {__('Recent Roles:')}
                                                            </label>
                                                            <div className="flex flex-wrap gap-1">
                                                                {recentRoles.map((role, index) => (
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => handleRoleSelect({
                                                                            value: role.name,
                                                                            label: role.name,
                                                                            count: role.count
                                                                        })}
                                                                        className="walkthrough-airewritepopover-recentrolebutton text-2xs sm:text-xs bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-1 sm:px-2 py-0.5 sm:py-1 rounded-full ai-rewrite-recent-role-button do-not-close-popover"
                                                                    >
                                                                        {role.name}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Button
                                                        onClick={() => handleRewrite(RewriteAction.REWRITE, selectedRole?.value)}
                                                        className="walkthrough-airewritepopover-rewriteasrolebutton w-full justify-center bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white font-medium py-1 sm:py-2 px-2 sm:px-3 rounded-md transition-all duration-200 ai-rewrite-as-role-button text-xs sm:text-sm"
                                                        disabled={isRewriting || !selectedRole}
                                                        size="xs"
                                                    >
                                                        {isRewriting ? __('Rewriting...') : __('Rewrite as {{role}}', { placeholders: { role: selectedRole?.label || __('Selected Role') } })}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <Button
                                            onClick={() => handleRewrite(option.action)}
                                            className={`walkthrough-airewritepopover-${option.action.toLowerCase()}button w-full justify-start bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-medium py-1 sm:py-2 px-2 sm:px-3 rounded-md transition-all duration-200 ai-rewrite-${option.action.toLowerCase()}-button flex items-center text-xs sm:text-sm`}
                                            disabled={isRewriting}
                                            size="xs"
                                        >
                                            <option.icon className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                                            <span>{option.label}</span>
                                            <StrengthIndicator strength={option.strength} className="ml-auto" />
                                        </Button>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </PopoverContent>
                </PopoverPortal>
            </PopoverRoot>
        );
    }
);

AIRewritePopover.displayName = 'AIRewritePopover';
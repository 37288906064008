import { copyToClipboard } from "@/utils/clipboard";
import { showToast } from "@/utils/toast";
import { Note } from '@/lib/notes/Note';
import { __, TranslationOptions } from '@/utils/translationUtils';

export const getFootnote = (username: string) => {
  const options: TranslationOptions = {
    source: 'userContent',
    placeholders: { username }
  };
  return '\n\n' + __('🎙️ Voice by {{username}} | ✍️ Typed by Sondeas', options);
};

export const handleShare = (text: string, username: string, action: (textWithFootnote: string) => void, addFootnote: boolean = true) => {
  const textToShare = addFootnote ? text + getFootnote(username) : text;
  action(textToShare);
};

export const createShareHandlers = (text: string, username: string) => ({
  handleCopy: async () => {
    await copyToClipboard(text + getFootnote(username));
    showToast(__('Text copied to clipboard', { source: 'uiComponents' }), 'success');
  },
  handleEmail: () => {
    const subject = encodeURIComponent(__('Check out this transcription from Sondeas!', { source: 'userContent' }));
    const body = encodeURIComponent(text + getFootnote(username));
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  },
  handleTwitter: () => {
    const tweetText = encodeURIComponent(text.slice(0, 240) + "..." + getFootnote(username));
    window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
  },
  handleChatGPT: () => handleShare(text, username, (textToShare) => 
    window.open(`https://chatgpt.com/?model=gpt-4&q=${encodeURIComponent(textToShare)}`, '_blank'),
    false
  ),
  handleClaude: () => handleShare(text, username, (textToShare) => 
    window.open(`https://claude.ai/new?q=${encodeURIComponent(textToShare)}`, '_blank'),
    false
  ),
  handleWhatsApp: () => handleShare(text, username, (textWithFootnote) => 
    window.open(`https://wa.me/?text=${encodeURIComponent(textWithFootnote)}`, '_blank')
  ),
  handleTelegram: () => handleShare(text, username, (textWithFootnote) => 
    window.open(`https://t.me/share/url?url=${encodeURIComponent(textWithFootnote)}`, '_blank')
  ),
  handlePerplexity: () => handleShare(text, username, (textToShare) => 
    window.open(`https://www.perplexity.ai/search/?q=${encodeURIComponent(textToShare)}`, '_blank'),
    false
  ),
});

export const handleCopyMultiple = async (items: Note[]) => {
  const textToCopy = items
    .map((item) => item.content || '')
    .join('\n\n');
  await copyToClipboard(textToCopy);
  showToast(__('Items copied to clipboard', { source: 'uiComponents' }), 'success');
};
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  KeyboardEvent,
  forwardRef,
  ReactNode
} from "react";
import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";
import showdown from "showdown";
import TurndownService from "turndown";
import { copyToClipboard } from "@/utils/clipboard";
import { __, translationSources } from "@/utils/translationUtils";
import { showToast } from "@/utils/toast";
import { twMerge } from "tailwind-merge";
import type { Components } from "react-markdown";

interface VerboseJsonSegment {
  text: string;
  avg_logprob: number;
  id: number;
  start: number;
  end: number;
  speaker?: number;
}

interface VerboseJson {
  text: string;
  language: string;
  segments: VerboseJsonSegment[];
}

interface BestMatch {
  segment: VerboseJsonSegment | null;
  score: number;
}

interface TextAreaProps {
  value: string;
  onChange?: (value: string, plainText: string) => void;
  mode?: "stt" | "tts" | "edit" | "preview";
  key?: string;
  verboseJson?: VerboseJson;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isInitiallyEditing?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

// Accessible color pairs that work in both light and dark modes and are color-blind friendly
const SPEAKER_COLORS = [
  { bg: "bg-blue-100 dark:bg-blue-900", text: "text-blue-800 dark:text-blue-200" },
  { bg: "bg-amber-100 dark:bg-amber-900", text: "text-amber-800 dark:text-amber-200" },
  { bg: "bg-emerald-100 dark:bg-emerald-900", text: "text-emerald-800 dark:text-emerald-200" },
  { bg: "bg-purple-100 dark:bg-purple-900", text: "text-purple-800 dark:text-purple-200" }
];

interface MarkdownComponentProps {
  node?: any;
  children?: ReactNode;
  className?: string;
  [key: string]: any;
}

/**
 * Returns a set of shared ReactMarkdown component overrides
 * so we don't have to duplicate them in multiple places.
 * The `p` component is left out because `TextArea` and `ReadOnlyTextArea`
 * handle paragraph rendering differently.
 */
function getSharedMarkdownComponents(
  handleClick: (e: React.MouseEvent) => void
): Partial<Components> {
  return {
    h1: ({ children, ...props }: MarkdownComponentProps) => (
      <h1
        onClick={handleClick}
        className="text-2xl font-bold my-4 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </h1>
    ),
    h2: ({ children, ...props }: MarkdownComponentProps) => (
      <h2
        onClick={handleClick}
        className="text-xl font-bold my-3 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </h2>
    ),
    h3: ({ children, ...props }: MarkdownComponentProps) => (
      <h3
        onClick={handleClick}
        className="text-lg font-bold my-2 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </h3>
    ),
    h4: ({ children, ...props }: MarkdownComponentProps) => (
      <h4
        onClick={handleClick}
        className="text-base font-bold my-2 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </h4>
    ),
    h5: ({ children, ...props }: MarkdownComponentProps) => (
      <h5
        onClick={handleClick}
        className="text-sm font-bold my-1 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </h5>
    ),
    h6: ({ children, ...props }: MarkdownComponentProps) => (
      <h6
        onClick={handleClick}
        className="text-xs font-bold my-1 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </h6>
    ),
    ul: ({ children, ...props }: MarkdownComponentProps) => (
      <ul
        onClick={handleClick}
        className="list-disc pl-5 my-2 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </ul>
    ),
    ol: ({ children, ...props }: MarkdownComponentProps) => (
      <ol
        onClick={handleClick}
        className="list-decimal pl-5 my-2 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </ol>
    ),
    li: ({ children, ...props }: MarkdownComponentProps) => (
      <li onClick={handleClick} className="my-1 text-gray-800 dark:text-white" {...props}>
        {children}
      </li>
    ),
    blockquote: ({ children, ...props }: MarkdownComponentProps) => (
      <blockquote
        onClick={handleClick}
        className="border-l-4 border-gray-300 pl-4 italic my-2 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </blockquote>
    ),
    code: ({ children, className, ...props }: MarkdownComponentProps) => {
      const isInline = !className || !className.includes("language-");
      return isInline ? (
        <code
          onClick={handleClick}
          className="bg-gray-100 dark:bg-gray-800 rounded px-1 text-gray-800 dark:text-white"
          {...props}
        >
          {children}
        </code>
      ) : (
        <pre
          onClick={handleClick}
          className="bg-gray-100 dark:bg-gray-800 rounded p-4 my-2 overflow-x-auto"
          {...props}
        >
          <code {...props}>{children}</code>
        </pre>
      );
    },
    table: ({ children, ...props }: MarkdownComponentProps) => (
      <table
        onClick={handleClick}
        className="border-collapse table-auto w-full my-2 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </table>
    ),
    th: ({ children, ...props }: MarkdownComponentProps) => (
      <th
        onClick={handleClick}
        className="border px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </th>
    ),
    td: ({ children, ...props }: MarkdownComponentProps) => (
      <td
        onClick={handleClick}
        className="border px-4 py-2 text-gray-800 dark:text-white"
        {...props}
      >
        {children}
      </td>
    )
  };
}

/**
 * Normalize text by trimming, removing extra whitespace, punctuation, etc.
 * 
 * @param input The raw text to normalize
 * @returns A "cleaned up" version for matching
 */
function normalizeTextForMatching(input: string): string {
  return input
    .replace(/[^a-zA-Z0-9\u00C0-\u024F]+/g, " ") // remove punctuation and keep letters, numbers, and accented chars
    .trim()
    .toLowerCase();
}

/**
 * Rough fuzzy-check: let's see how many words line up.
 * We won't do a library-based Levenshtein because we don't want to 
 * blow out the complexity. This is enough to handle minor edits/spaces.
 * 
 * If at least ~80% of the words match, we treat it as "the same paragraph."
 */
function isFuzzyMatch(paragraph: string, segmentText: string): boolean {
  // Extract only letters and convert to lowercase
  const normPar = paragraph.replace(/[^a-zA-Z]/g, '').toLowerCase();
  const normSeg = segmentText.replace(/[^a-zA-Z]/g, '').toLowerCase();

  if (!normPar || !normSeg) return false;

  // Compare lengths to determine which is longer
  const [shorter, longer] = 
    normPar.length < normSeg.length ? [normPar, normSeg] : [normSeg, normPar];

  let differences = 0;
  for (let i = 0; i < shorter.length; i++) {
    if (shorter[i] !== longer[i]) {
      differences++;
    }
  }
  
  // Add remaining length difference as differences
  differences += longer.length - shorter.length;

  // Calculate ratio of differences to total length
  const ratio = differences / longer.length;
  return ratio <= 0.1; // Allow up to 10% differences
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      value,
      onChange,
      mode = "edit",
      verboseJson,
      className = "",
      placeholder = __("Your spoken words will appear here...", { source: translationSources.audio }),
      readOnly = false,
      onKeyDown,
      isInitiallyEditing = true,
      onClick
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(isInitiallyEditing);
    const internalRef = useRef<HTMLTextAreaElement>(null);
    const textAreaRef = (ref as React.RefObject<HTMLTextAreaElement>) || internalRef;

    const converter = new showdown.Converter({
      headerLevelStart: 1,
      strikethrough: true,
      tables: true,
      tasklists: true,
      smoothLivePreview: true,
      smartIndentationFix: true,
      simpleLineBreaks: true
    });

    const turndownService = new TurndownService({
      headingStyle: "atx",
      codeBlockStyle: "fenced",
      emDelimiter: "*"
    });

    const handlePaste = useCallback(
      (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || (window as any).clipboardData;
        let pastedContent = "";

        if (clipboardData.types.includes("text/html")) {
          const htmlContent = clipboardData.getData("text/html");
          pastedContent = turndownService.turndown(htmlContent);
        } else {
          pastedContent = clipboardData.getData("text/plain");
        }

        if (textAreaRef.current) {
          const start = textAreaRef.current.selectionStart;
          const end = textAreaRef.current.selectionEnd;
          const newValue =
            value.substring(0, start) + pastedContent + value.substring(end);
          onChange?.(newValue, newValue);

          setTimeout(() => {
            if (textAreaRef.current) {
              textAreaRef.current.selectionStart =
                textAreaRef.current.selectionEnd =
                  start + pastedContent.length;
            }
          }, 0);
        }
      },
      [value, onChange, turndownService]
    );

    // Confidence-based fallback
    const getTokenProbability = (text: string): number | null => {
      if (!verboseJson) return null;

      let bestMatch: BestMatch = { segment: null, score: 0 };
      for (const segment of verboseJson.segments) {
        const words = segment.text.split(/\s+/);
        const textWords = text.split(/\s+/);

        let score = 0;
        for (const word of textWords) {
          if (words.includes(word)) {
            score++;
          }
        }

        if (score > bestMatch.score) {
          bestMatch = { segment, score };
        }
      }
      return bestMatch.segment ? Math.exp(bestMatch.segment.avg_logprob) : null;
    };

    const getHighlightColor = (probability: number): string => {
      if (probability >= 0.95) {
        return "yellow";
      }
      if (probability >= 0.85) {
        return "rgba(255, 165, 0, 0.4)"; // Orange
      }
      if (probability >= 0.75) {
        return "rgba(255, 0, 0, 0.4)"; // Red
      }
      return "transparent";
    };

    const handlePreviewClick = () => {
      if (!readOnly) {
        setIsEditing(true);
        setTimeout(() => {
          if (textAreaRef.current) {
            textAreaRef.current.focus();
          }
        }, 0);
      }
    };

    const handleContentClick = (e: React.MouseEvent) => {
      console.debug("[TextArea] Click in main TextArea:", {
        readOnly,
        hasValue: !!value,
        hasOnClick: !!onClick,
        target: e.target,
        currentTarget: e.currentTarget
      });
      e.stopPropagation();

      if (onClick) {
        onClick(e);
      } else if (!readOnly) {
        handlePreviewClick();
      }
    };

    const renderContent = () => {
      if (!isEditing || readOnly) {
        return (
          <div
            className={twMerge(
              "flex-grow p-4 rounded-2xl bg-white dark:bg-gray-900 text-gray-800 dark:text-white dark:border-gray-700 transition-all duration-300 text-base shadow-inner overflow-y-auto cursor-pointer ph-no-capture",
              className
            )}
            onClick={handleContentClick}
            style={{ boxSizing: "border-box" }}
          >
            {value ? (
              <ReactMarkdown
                components={{
                  ...getSharedMarkdownComponents(handleContentClick),
                  p: ({ children }) => {
                    // This chunk handles speaker-based or confidence-based highlighting
                    const childrenArray = React.Children.toArray(children);
                    if (!children || childrenArray.length === 0) {
                      return (
                        <p className="text-gray-800 dark:text-white whitespace-pre-line">
                          {children}
                        </p>
                      );
                    }

                    const text = childrenArray[0].toString();
                    const hasSpeakers =
                      verboseJson?.segments?.some((s) => s.speaker !== undefined) ||
                      false;

                    // Split the current text into paragraphs
                    const paragraphs = text
                      .split("\n\n")
                      .map((p) => p.trim())
                      .filter((p) => p);

                    return (
                      <div className="space-y-4">
                        {paragraphs.map((paragraph, index) => {
                          // Check each segment for a fuzzy match
                          const matchingSegment = verboseJson?.segments?.find((segment) =>
                            isFuzzyMatch(paragraph, segment.text)
                          );

                          if (matchingSegment) {
                            // If we have a matching segment and speakers, use speaker-based coloring
                            if (hasSpeakers) {
                              const speakerIndex = (matchingSegment.speaker || 1) - 1;
                              const colors =
                                SPEAKER_COLORS[speakerIndex % SPEAKER_COLORS.length];

                              return (
                                <div
                                  key={index}
                                  className={`rounded-lg px-3 py-2 ${colors.bg}`}
                                >
                                  <p className={`${colors.text} whitespace-pre-line`}>
                                    {paragraph}
                                  </p>
                                </div>
                              );
                            }

                            // If we have a matching segment but no speakers, use confidence-based highlighting
                            const probability = Math.exp(matchingSegment.avg_logprob);
                            const backgroundColor = getHighlightColor(probability);
                            return (
                              <p
                                key={index}
                                className="text-gray-800 dark:text-white whitespace-pre-line"
                                style={{ backgroundColor }}
                              >
                                {paragraph}
                              </p>
                            );
                          }

                          // No matching segment, render without highlighting
                          return (
                            <p
                              key={index}
                              className="text-gray-800 dark:text-white whitespace-pre-line"
                            >
                              {paragraph}
                            </p>
                          );
                        })}
                      </div>
                    );
                  }
                }}
              >
                {value}
              </ReactMarkdown>
            ) : (
              <div className="italic text-gray-400 dark:text-gray-500">
                {placeholder}
              </div>
            )}
          </div>
        );
      }

      // Rendering Editable TextArea
      return (
        <textarea
          ref={textAreaRef}
          value={value}
          onChange={(e) => onChange?.(e.target.value, e.target.value)}
          onPaste={handlePaste}
          onKeyDown={onKeyDown}
          className={`w-full h-full min-h-[200px] p-4 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 resize-none outline-none rounded-2xl ${className} flex-1`}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      );
    };

    return (
      <div className="relative flex-grow flex flex-col min-h-0">
        {!readOnly && (
          <div className="flex items-center justify-end mb-3">
            <div className="inline-flex items-center p-0.5 rounded-lg bg-gray-100 dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
              <button
                className={`px-4 py-1.5 text-sm font-medium rounded-md transition-all duration-200 ${
                  isEditing
                    ? "bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm"
                    : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                }`}
                onClick={() => setIsEditing(true)}
                aria-pressed={isEditing}
              >
                {__("Edit", { source: translationSources.uiComponents })}
              </button>
              <button
                className={`px-4 py-1.5 text-sm font-medium rounded-md transition-all duration-200 ${
                  !isEditing
                    ? "bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-sm"
                    : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                }`}
                onClick={() => setIsEditing(false)}
                aria-pressed={!isEditing}
              >
                {__("Preview", { source: translationSources.uiComponents })}
              </button>
            </div>
          </div>
        )}
        <div className="flex-grow flex flex-col overflow-hidden min-h-0">
          {renderContent()}
        </div>
      </div>
    );
  }
);

TextArea.displayName = "TextArea";

interface ReadOnlyTextAreaProps {
  value: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const ReadOnlyTextArea: React.FC<ReadOnlyTextAreaProps> = ({
  value,
  onClick
}) => {
  const placeholder = __("Your spoken words will appear here...", { source: translationSources.audio });

  const handleClick = (e: React.MouseEvent) => {
    console.debug("[TextArea] Click event in ReadOnlyTextArea:", {
      hasValue: !!value,
      hasOnClick: !!onClick,
      target: e.target,
      currentTarget: e.currentTarget,
      tagName: (e.target as HTMLElement).tagName,
      className: (e.target as HTMLElement).className
    });
    // Always stop propagation
    e.stopPropagation();

    // Always call onClick first if provided
    if (onClick) {
      onClick(e);
    }

    // Copy to clipboard after onClick
    if (value) {
      copyToClipboard(value);
    }
  };

  return (
    <div className="relative flex-grow flex flex-col" onClick={handleClick}>
      <div
        className="flex-grow w-full p-4 pt-10 border border-gray-300 rounded-2xl bg-white dark:bg-gray-800 text-gray-800 dark:text-white dark:border-gray-700 transition-all duration-300 text-base shadow-inner overflow-y-auto cursor-pointer ph-no-capture"
        style={{ boxSizing: "border-box" }}
      >
        {value ? (
          <ReactMarkdown
            components={{
              ...getSharedMarkdownComponents(handleClick),
              // We keep <p> simple here; no speaker-based logic or highlighting
              p: ({ ...props }) => (
                <p
                  onClick={handleClick}
                  className="text-gray-800 dark:text-white whitespace-pre-line"
                  {...props}
                />
              )
            }}
          >
            {value}
          </ReactMarkdown>
        ) : (
          <div className="italic text-gray-400 dark:text-gray-500">
            {placeholder}
          </div>
        )}
      </div>
      {value && (
        <motion.div
          className="absolute top-2 left-2 px-2 py-1 bg-gray-200 dark:bg-gray-700 text-xs text-gray-600 dark:text-gray-300 rounded-full shadow-md cursor-pointer ph-no-capture"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          onClick={handleClick}
        >
          {__("Tap to copy", { source: translationSources.uiComponents })}
        </motion.div>
      )}
    </div>
  );
};

export default TextArea;

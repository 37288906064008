import React, { createContext, useContext, useState, useCallback } from 'react';
import { Note } from '@/lib/notes/Note';

interface AudioPlayerContextType {
  currentNote: Note | null;
  currentAudioIndex: number;
  isPlaying: boolean;
  play: (note: Note, startAudioIndex: number) => void;
  pause: () => void;
  next: () => void;
  previous: () => void;
  setCurrentAudioIndex: (index: number) => void;
}

const AudioPlayerContext = createContext<AudioPlayerContextType | undefined>(undefined);

export const useAudioPlayer = () => {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider');
  }
  return context;
};

export const AudioPlayerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentNote, setCurrentNote] = useState<Note | null>(null);
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const play = useCallback((note: Note, startAudioIndex: number) => {
    setCurrentNote(note);
    setCurrentAudioIndex(startAudioIndex);
    setIsPlaying(true);
  }, []);

  const pause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const next = useCallback(() => {
    if (currentNote) {
      setCurrentAudioIndex((prevIndex) => 
        prevIndex < currentNote.audios.length - 1 ? prevIndex + 1 : 0
      );
    }
  }, [currentNote]);

  const previous = useCallback(() => {
    if (currentNote) {
      setCurrentAudioIndex((prevIndex) => 
        prevIndex > 0 ? prevIndex - 1 : currentNote.audios.length - 1
      );
    }
  }, [currentNote]);

  return (
    <AudioPlayerContext.Provider
      value={{
        currentNote,
        currentAudioIndex,
        isPlaying,
        play,
        pause,
        next,
        previous,
        setCurrentAudioIndex,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

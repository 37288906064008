import toast from "react-hot-toast";

// src/utils/toast.ts
export const showToast = (message: string, type: 'success' | 'error' | 'info') => {
    switch (type) {
        case 'success':
            toast.success(message, {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
            break;
        case 'error':
            toast.error(message, {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
            break;
        case 'info':
            toast(message, {
                icon: '🔔',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
            break;
        default:
            toast(message, {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
    }
};
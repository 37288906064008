// src/utils/clipboard.ts
import { showToast } from "@/utils/toast";
import { __, TranslationOptions } from '@/utils/translationUtils';

export const copyToClipboard = async (text: string) => {
    const translationOptions: TranslationOptions = {
        source: 'uiComponents',
    };

    try {
        if (document.hasFocus()) {
            await navigator.clipboard.writeText(text);
        } else {
            // Fallback method when document is not focused
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        showToast(__('Text copied to clipboard', translationOptions), 'success');
    } catch (error) {
        console.error(__('Failed to copy text:', translationOptions), error);
        showToast(__('Failed to copy text to clipboard', translationOptions), 'error');
    }
};
import React, { useMemo, useState } from 'react';
import { useNoteEditor } from './NoteEditorContext';
import TextArea from '@/components/TextArea';
import { Button } from '@/components/ui/button';
import VersionArrows from '@/components/VersionArrows';
import { AudioControls } from '@/components/AudioControls';
import { twMerge } from 'tailwind-merge';
import { __ } from '@/utils/translationUtils';
import AudioPlayer from "@/components/AudioPlayer";
import { toast } from 'react-hot-toast';
import { useAudioPlayer } from '@/contexts/AudioPlayerContext';

interface NoteEditorLayoutProps {
  className?: string;
}

export const NoteEditorLayout: React.FC<NoteEditorLayoutProps> = ({ className }) => {
  const { 
    note,
    content, 
    setContent,
    hasChanges,
    handleSave,
    handleDiscard,
    isSaving,
    handleTranslate,
    handleRewrite,
    isTranslating,
    isRewriting,
    versionIndex,
    totalVersions,
    handleVersionChange
  } = useNoteEditor();

  const { isPlaying, currentNote, currentAudioIndex } = useAudioPlayer();
  const isCurrentlyPlaying = isPlaying && currentNote?.id === note.id;

  const [isReadOnly, setIsReadOnly] = useState(false);

  // Get verboseJson from the current audio's metas
  const verboseJson = useMemo(() => {
    if (!note.audios?.[0]?.metas) return undefined;
    const verboseJsonMeta = note.audios[0].metas.find(m => m.key === 'verboseJson');
    if (!verboseJsonMeta?.value) return undefined;
    
    // Handle both string and object values
    if (typeof verboseJsonMeta.value === 'string') {
      try {
        return JSON.parse(verboseJsonMeta.value);
      } catch (e) {
        console.error('Failed to parse verboseJson:', e);
        return undefined;
      }
    }
    
    // If it's already an object, return it directly
    return verboseJsonMeta.value;
  }, [note.audios]);

  const handleCopy = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      toast.success(__('Text copied to clipboard'));
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error(__('Failed to copy text'));
    }
  };

  return (
    <>
      <div className={twMerge(
        'note-editor flex flex-col gap-4 p-4',
        'bg-white dark:bg-gray-800 rounded-lg shadow-lg',
        className
      )}>
        <div className="flex-grow flex flex-col gap-4">
          <div className="walkthrough-note-textarea-container relative flex-grow flex flex-col">
            <TextArea
              value={content}
              onChange={(value: string, plainText: string) => setContent(plainText)}
              mode="stt"
              verboseJson={verboseJson}
              readOnly={isReadOnly}
            />
            
            {note.versions && note.versions.length > 0 && (
              <VersionArrows
                currentVersionIndex={versionIndex}
                totalVersions={totalVersions}
                onPrevious={() => handleVersionChange(versionIndex + 1)}
                onNext={() => handleVersionChange(versionIndex - 1)}
                className="walkthrough-note-version-arrows absolute bottom-2 right-2"
              />
            )}
          </div>

          {hasChanges && (
            <div className="walkthrough-note-edit-actions flex justify-end gap-2">
              <Button 
                variant="outline" 
                onClick={handleDiscard}
                className="walkthrough-note-discard-button"
              >
                {__('Discard')}
              </Button>
              <Button 
                variant="default"
                onClick={handleSave}
                disabled={isSaving}
                className="walkthrough-note-save-button"
              >
                {isSaving ? __('Saving...') : __('Save Changes')}
              </Button>
            </div>
          )}

          {isCurrentlyPlaying && note.audios?.length > 0 && currentAudioIndex !== undefined && (
            <AudioPlayer
              audioSrc={`/api/audio/${note.audios[currentAudioIndex].id}`}
              className="walkthrough-note-audio-player mt-2 mb-2"
            />
          )}

          <AudioControls
            item={note}
            onCopy={handleCopy}
            onTranslate={handleTranslate}
            text={content}
            isTranslating={isTranslating}
            noteId={note.id}
            onRewrite={handleRewrite}
            isRewriting={isRewriting}
            className="walkthrough-note-audio-controls"
          />
        </div>
      </div>
    </>
  );
};

'use client';

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { FiArchive, FiEdit, FiFile, FiMoreVertical } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import TimeAgo from 'react-timeago';
import Modal from './Modal';
import { twMerge } from 'tailwind-merge';
import { useLongPress } from '@/hooks/useLongPress';
import { Button } from './ui/button';
import OptionsPopover from './OptionsPopover';
import VersionArrows from './VersionArrows';
import { AudioControls } from './AudioControls';
import { Provider as TooltipProvider, Root as TooltipRoot, Trigger as TooltipTrigger, Portal as TooltipPortal, Content as TooltipContent, Arrow as TooltipArrow } from '@radix-ui/react-tooltip';
import { __ } from '@/utils/translationUtils';
import AudioPlayer from "@/components/AudioPlayer";
import { useAudioPlayer } from '@/contexts/AudioPlayerContext';
import { Note } from "@/lib/notes/Note";
import { NoteEditorProvider } from '@/contexts/NoteEditor/NoteEditorContext';
import { NoteEditorLayout } from '@/contexts/NoteEditor/NoteEditorLayout';
import { useNoteEditor } from '@/contexts/NoteEditor/NoteEditorContext';
import TextArea from '@/components/TextArea';

interface AudioRecordingComponentProps {
    item: Note;
    isExpanded: boolean;
    onToggle: () => void;
    onDelete: (id: string) => Promise<void>;
    onArchive: (id: string) => void;
    onUpdate: (updatedItem: Note) => void;
    className?: string;
    style?: React.CSSProperties;
    customIcon?: React.ReactNode;
    isSelected: boolean;
    selectionNumber: number;
    onSelect: (event?: React.MouseEvent) => void;
    isSelectionMode: boolean;
    onEnterSelectionMode: () => void;
}

interface AudioRecordingContextValue {
    item: Note;
    versionIndex: number;
    setVersionIndex: (index: number) => void;
    getText: string;
    isPlaying: boolean;
    currentAudioId: string | null;
    handlePlay: (e: React.MouseEvent, audioId: string) => void;
    handleCopy: (e: React.MouseEvent) => void;
    openModal: (e: React.MouseEvent) => void;
    closeModal: () => void;
    isModalOpen: boolean;
    typeIcon: React.ReactNode;
    durationDisplay: React.ReactNode;
    totalVersions: number;
    handleVersionChange: (newIndex: number) => void;
    customIcon?: React.ReactNode;
    onUpdate?: (note: Note) => void;
}

const AudioRecordingContext = React.createContext<AudioRecordingContextValue | undefined>(undefined);

export const formatDuration = (seconds: number): string => {
    if (!isFinite(seconds) || isNaN(seconds)) {
        return "00:00";
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const AudioRecordingHeader: React.FC<{
    isSelectionMode: boolean;
    onEdit: () => void;
    onArchive: () => void;
    onDelete: () => void;
    onSelect: () => void;
    onEnterSelectionMode: () => void;
}> = ({
    isSelectionMode,
    onEdit,
    onArchive,
    onDelete,
    onSelect,
    onEnterSelectionMode,
}) => {
    const context = useContext(AudioRecordingContext);
    if (!context) {
        throw new Error('AudioRecordingHeader must be used within an AudioRecordingContext.Provider');
    }
    const { item, typeIcon, durationDisplay } = context;

    const isEdited = new Date(item.updatedAt).getTime() !== new Date(item.createdAt).getTime();

    return (
        <div className="walkthrough-audiorecording-header flex justify-between items-center p-1">
            <div className="walkthrough-audiorecording-headerinfo flex items-center">
                {typeIcon}
                <TimeAgo
                    date={item.createdAt}
                    className="walkthrough-audiorecording-timeago text-xs sm:text-sm text-gray-500 dark:text-gray-400 ml-2"
                    formatter={(value, unit, suffix) => {
                        const unitAbbr = unit[0];
                        return `${value}${unitAbbr} ${suffix}`;
                    }}
                />
                {item.isArchived && (
                    <div aria-label={__('Archived')} role="tooltip">
                        <FiArchive
                            className="walkthrough-audiorecording-archivedicon ml-2 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                        />
                        <span className="sr-only">{__('Archived')}</span>
                    </div>
                )}
            </div>
            <div className="walkthrough-audiorecording-headercontrols flex items-center space-x-2 sm:space-x-4">
                <div className="flex items-center">
                    {isEdited && (
                        <FiEdit className="text-gray-500 dark:text-gray-400 mr-1" size={12} />
                    )}
                    <TimeAgo
                        date={item.updatedAt}
                        className="walkthrough-audiorecording-updatedat text-xs sm:text-sm text-gray-500 dark:text-gray-400"
                        formatter={(value, unit, suffix) => {
                            const unitAbbr = unit[0];
                            return `${value}${unitAbbr} ${suffix}`;
                        }}
                    />
                </div>
                {durationDisplay}
                <OptionsPopover
                    onEdit={onEdit}
                    onArchive={onArchive}
                    onDelete={onDelete}
                    isArchived={item.isArchived}
                    onSelect={() => {
                        if (!isSelectionMode) {
                            onEnterSelectionMode();
                        }
                        onSelect();
                    }}
                    isSelectionMode={isSelectionMode}
                >
                    <Button variant="ghost" size="xs" className="walkthrough-audiorecording-optionsbutton">
                        <FiMoreVertical className="h-4 w-4" />
                    </Button>
                </OptionsPopover>
            </div>
        </div>
    );
};

const AudioRecordingContent: React.FC<{
    openModal: (e: React.MouseEvent) => void;
}> = ({ openModal }) => {
    const context = useContext(AudioRecordingContext);
    if (!context) {
        throw new Error('AudioRecordingContent must be used within an AudioRecordingContext.Provider');
    }
    const { getText, item, versionIndex, totalVersions, handleVersionChange } = context;

    return (
        <div
            className="walkthrough-audiorecording-textcontent rounded-lg flex-grow relative cursor-pointer ph-no-capture"
        >
            <div
                className="walkthrough-audiorecording-text-wrapper max-h-[calc(1.5rem*10)] md:max-h-[calc(1.5rem*10)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent hover:scrollbar-thumb-gray-400 dark:hover:scrollbar-thumb-gray-500 ph-no-capture relative"
            >
                <div 
                    className="absolute inset-0 z-10"
                    onClick={openModal}
                    aria-label={__('Click to open note')}
                />
                <TextArea
                    value={getText}
                    readOnly={true}
                    mode="stt"
                    verboseJson={useMemo(() => {
                        if (!item.audios?.[0]?.metas) return undefined;
                        const verboseJsonMeta = item.audios[0].metas.find(m => m.key === 'verboseJson');
                        if (!verboseJsonMeta?.value) return undefined;
                        return typeof verboseJsonMeta.value === 'string' 
                            ? JSON.parse(verboseJsonMeta.value) 
                            : verboseJsonMeta.value;
                    }, [item.audios])}
                    className={`walkthrough-audiorecording-text text-sm sm:text-base pr-6 p-2 sm:p-3 ${item.versions.length > 0 ? '!pb-10' : ''} whitespace-pre-line ph-no-capture`}
                />
            </div>
            {item.versions.length > 0 && (
                <VersionArrows
                    currentVersionIndex={versionIndex}
                    totalVersions={totalVersions}
                    onPrevious={() => handleVersionChange(versionIndex + 1)}
                    onNext={() => handleVersionChange(versionIndex - 1)}
                    className="walkthrough-audiorecording-versionarrows absolute bottom-1 right-1 ph-no-capture"
                />
            )}
        </div>
    );
};

const AudioRecordingModal: React.FC = () => {
    const context = useContext(AudioRecordingContext);
    if (!context) {
        throw new Error('AudioRecordingModal must be used within an AudioRecordingContext.Provider');
    }
    const {
        isModalOpen,
        closeModal,
        item,
        onUpdate,
    } = context;

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={__('Note')}
            fullScreenOnMobile={true}
            className="walkthrough-audiorecording-modal flex flex-col flex-grow md:min-h-[70vh] md:min-w-[60vw]"
        >
            <NoteEditorProvider note={item} onUpdate={onUpdate}>
                <NoteEditorLayout className="flex-grow" />
            </NoteEditorProvider>
        </Modal>
    );
};

const AudioRecordingComponent: React.FC<AudioRecordingComponentProps> = React.memo((props) => {
    const {
        item,
        isExpanded,
        onToggle,
        onDelete,
        onArchive,
        onUpdate,
        className = '',
        style = {},
        customIcon,
        isSelected,
        selectionNumber,
        onSelect,
        isSelectionMode,
        onEnterSelectionMode,
    } = props;

    return (
        <NoteEditorProvider note={item} onUpdate={onUpdate}>
            <AudioRecordingComponentInner {...props} />
        </NoteEditorProvider>
    );
});

const AudioRecordingComponentInner: React.FC<AudioRecordingComponentProps> = ({
    item,
    isExpanded,
    onToggle,
    onDelete,
    onArchive,
    onUpdate,
    className = '',
    style = {},
    customIcon,
    isSelected,
    selectionNumber,
    onSelect,
    isSelectionMode,
    onEnterSelectionMode,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [versionIndex, setVersionIndex] = useState(0);
    const noteEditorContext = useNoteEditor();

    const {
        currentNote,
        currentAudioIndex,
        isPlaying,
        play,
        pause,
    } = useAudioPlayer();

    const totalVersions = useMemo(() => item.versions.length + 1, [item.versions.length]);

    const getText = useMemo(() => {
        if (!item) return '';
        if (versionIndex === 0) return item.content || __('No content available');
        const version = item.versions?.[versionIndex - 1];
        return version?.content || __('No content available');
    }, [item, versionIndex]);

    const handleVersionChange = useCallback((newIndex: number) => {
        setVersionIndex(newIndex);
    }, []);

    const handlePlay = useCallback(
        (e: React.MouseEvent, audioId: string) => {
            e.stopPropagation();
            const audioIndex = item.audios.findIndex(audio => audio.id === audioId);
            if (audioIndex === -1) {
                console.error(__('Audio not found'));
                return;
            }
            if (isPlaying && currentNote?.id === item.id && currentAudioIndex === audioIndex) {
                pause();
            } else {
                play(item, audioIndex);
            }
        },
        [isPlaying, currentNote, currentAudioIndex, item, play, pause]
    );

    const handleCopy = useCallback(async (e: React.MouseEvent) => {
        if (!(e.target as HTMLElement).closest('.share-popover')) {
            e.stopPropagation();
            try {
                if (getText) {
                    await navigator.clipboard.writeText(getText);
                    toast.success(__('Text copied to clipboard'));
                } else {
                    toast.error(__('No text available to copy'));
                }
            } catch (err) {
                console.error(__('Failed to copy text: '), err);
                toast.error(__('Failed to copy text'));
            }
        }
    }, [getText]);

    const openModal = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        pause();
    }, [pause]);

    const typeIcon = useMemo(() => {
        if (customIcon) return customIcon;
        return <FiFile className="walkthrough-audiorecording-typeicon text-gray-500" />;
    }, [customIcon]);

    const handleArchive = useCallback(() => {
        onArchive(item.id);
    }, [onArchive, item.id]);

    const handleDeleteClick = useCallback(() => {
        onDelete(item.id);
    }, [onDelete, item.id]);

    const handleEdit = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleClick = useCallback((event?: React.MouseEvent) => {
        if (isSelectionMode) {
            onSelect(event);
        } else {
            onToggle();
        }
    }, [isSelectionMode, onSelect, onToggle]);

    const handleLongPress = useCallback(() => {
        if (!isSelectionMode) {
            onEnterSelectionMode();
            onSelect();
        }
    }, [isSelectionMode, onEnterSelectionMode, onSelect]);

    const longPressEvent = useLongPress({
        onLongPress: handleLongPress,
        onClick: handleClick,
        threshold: 700,
    });

    const durationDisplay = useMemo(() => {
        if (item.audios.length > 0) {
            const totalOriginalDuration = item.audios.reduce((sum, audio) => sum + (audio.originalDurationSeconds || 0), 0);
            const totalProcessedDuration = item.audios.reduce((sum, audio) => sum + (audio.processedDurationSeconds || audio.originalDurationSeconds || 0), 0);

            const originalDuration = formatDuration(totalOriginalDuration);
            const processedDuration = formatDuration(totalProcessedDuration);

            return (
                <TooltipProvider>
                    <TooltipRoot>
                        <TooltipTrigger asChild>
                            <span
                                className="walkthrough-audiorecording-duration text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300 cursor-help">
                                {originalDuration} ({processedDuration})
                            </span>
                        </TooltipTrigger>
                        <TooltipPortal>
                            <TooltipContent
                                className="walkthrough-audiorecording-durationtooltip bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 px-4 py-2 rounded-md shadow-md text-sm max-w-xs"
                                sideOffset={5}
                            >
                                <p>
                                    <strong>{__('Total Original Length:')}</strong> {originalDuration}
                                </p>
                                <p>
                                    <strong>{__('Total Optimized Length:')}</strong> {processedDuration}
                                </p>
                                <p>
                                    {__(
                                        "We've trimmed silences to save you time and money. This also makes transcriptions more accurate. You'll only be charged for the total optimized length ({{processedDuration}}).",
                                        { placeholders: { processedDuration } }
                                    )}
                                </p>
                                <TooltipArrow className="fill-current text-white dark:text-gray-800"/>
                            </TooltipContent>
                        </TooltipPortal>
                    </TooltipRoot>
                </TooltipProvider>
            );
        }
        return null;
    }, [item]);

    const hasAudios = useMemo(() => item.audios && item.audios.length > 0, [item.audios]);

    const audioControlsProps = {
        onCopy: handleCopy,
        text: getText,
        noteId: item.id,
        item,
        onTranslate: noteEditorContext.handleTranslate,
        isTranslating: noteEditorContext.isTranslating,
        onRewrite: noteEditorContext.handleRewrite,
        isRewriting: noteEditorContext.isRewriting,
        hasUnsavedChanges: noteEditorContext.hasChanges,
        onSaveChanges: async () => {
            await noteEditorContext.handleSave();
        },
    };

    if (!item) {
        return (
            <div
                className="walkthrough-audiorecording-loading h-full w-full flex items-center justify-center bg-gradient-to-br from-indigo-100 to-purple-100 dark:from-indigo-900 dark:to-purple-900 rounded-lg shadow-lg overflow-hidden animate-pulse"
            >
                <div className="text-center">
                    <div
                        className="walkthrough-audiorecording-loadingicon w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-200 dark:bg-indigo-700 flex items-center justify-center"
                    >
                        <svg
                            className="w-8 h-8 text-indigo-500 dark:text-indigo-300"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                            />
                        </svg>
                    </div>
                    <p className="walkthrough-audiorecording-loadingtext text-indigo-600 dark:text-indigo-300 font-semibold">
                        {__('Loading Audio Item...')}
                    </p>
                </div>
            </div>
        );
    }

    const contextValue: AudioRecordingContextValue = {
        item,
        versionIndex,
        setVersionIndex,
        getText,
        isPlaying: isPlaying && currentNote?.id === item.id,
        currentAudioId: currentNote?.id ?? null,
        handlePlay,
        handleCopy,
        openModal,
        closeModal,
        isModalOpen,
        typeIcon,
        durationDisplay,
        totalVersions,
        handleVersionChange,
        customIcon,
        onUpdate,
    };

    return (
        <AudioRecordingContext.Provider value={contextValue}>
            <div className="walkthrough-audiorecording-container relative h-full">
                {isSelected && (
                    <div
                        className="walkthrough-audiorecording-selectionnumber absolute top-0 left-0 -mt-3 -ml-3 bg-indigo-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold z-10 shadow-md"
                    >
                        {selectionNumber}
                    </div>
                )}
                <motion.div
                    className={twMerge(
                        "walkthrough-audiorecording-card rounded-lg shadow-lg overflow-hidden h-full flex flex-col relative",
                        "bg-white dark:bg-black text-gray-900/90 dark:text-gray-100",
                        className,
                        item.isArchived && "border-2 border-gray-400 dark:border-gray-500",
                        isSelected && "ring-2 ring-blue-500"
                    )}
                    style={style}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    {...(isSelectionMode ? {} : longPressEvent)}
                >
                    {isSelectionMode && (
                        <div
                            className="walkthrough-audiorecording-selectionoverlay absolute inset-0 bg-black/0 dark:bg-white/0 z-10"
                            onClick={(e) => onSelect(e)}
                        />
                    )}
                    <div className="walkthrough-audiorecording-content flex-grow flex flex-col relative">
                        <AudioRecordingHeader
                            isSelectionMode={isSelectionMode}
                            onEdit={handleEdit}
                            onArchive={handleArchive}
                            onDelete={handleDeleteClick}
                            onSelect={onSelect}
                            onEnterSelectionMode={onEnterSelectionMode}
                        />
                        <AudioRecordingContent openModal={openModal} />
                        {isPlaying && currentNote?.id === item.id && (
                            <AudioPlayer
                                audioSrc={`/api/audio/${item.audios[currentAudioIndex].id}`}
                                className="walkthrough-audiorecording-modalaudioplayer mt-2 mb-2"
                            />
                        )}
                        <AudioControls
                            {...audioControlsProps}
                            showPlay={hasAudios}
                            className="walkthrough-audiorecording-audiocontrols w-full"
                        />
                    </div>
                </motion.div>
            </div>
            <AudioRecordingModal />
        </AudioRecordingContext.Provider>
    );
};

AudioRecordingComponent.displayName = 'AudioRecordingComponent';

export default AudioRecordingComponent;

// Start of Selection
import React, { forwardRef, useState } from 'react';
import { useSession } from "next-auth/react";
import { FiCopy, FiMail, FiTwitter } from 'react-icons/fi';
import { SiOpenai, SiAnthropic, SiPerplexity } from 'react-icons/si';
import { FaWhatsapp, FaTelegram } from 'react-icons/fa';
import { Root, Trigger, Portal, Content } from '@radix-ui/react-popover';
import { PopoverButton } from "@/components/ui/PopoverButton";
import { createShareHandlers } from "@/utils/shareUtils";
import { __, translationSources } from '@/utils/translationUtils';

interface SharePopoverProps {
  onShare: (platform: string) => void;
  children: React.ReactNode;
  shareContent: string;
}

const SharePopover = forwardRef<HTMLDivElement, SharePopoverProps>(
  ({ onShare, children, shareContent }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const { data: session } = useSession();
    const username = session?.user?.firstName || __("me", { source: translationSources.userContent });
    
    const handleShare = (platform: string) => {
      const handlers = createShareHandlers(shareContent, username);
      const handler = handlers[`handle${platform}` as keyof typeof handlers];
      if (handler) {
        handler();
        onShare(platform);
        setIsOpen(false);
      }
    };

    const content = (
      <div className="flex flex-col space-y-2">
        <PopoverButton icon={<FiCopy />} label={__("Copy", { source: translationSources.uiComponents })} onClick={() => handleShare('Copy')} />
        <PopoverButton icon={<FiMail />} label={__("Email", { source: translationSources.uiComponents })} onClick={() => handleShare('Email')} />
        <PopoverButton icon={<FiTwitter />} label={__("Twitter", { source: translationSources.uiComponents })} onClick={() => handleShare('Twitter')} />
        <PopoverButton icon={<FaWhatsapp />} label={__("WhatsApp", { source: translationSources.uiComponents })} onClick={() => handleShare('WhatsApp')} />
        <PopoverButton icon={<FaTelegram />} label={__("Telegram", { source: translationSources.uiComponents })} onClick={() => handleShare('Telegram')} />
        <PopoverButton icon={<SiOpenai />} label={__("ChatGPT", { source: translationSources.uiComponents })} onClick={() => handleShare('ChatGPT')} />
        <PopoverButton icon={<SiAnthropic />} label={__("Claude", { source: translationSources.uiComponents })} onClick={() => handleShare('Claude')} />
        <PopoverButton icon={<SiPerplexity />} label={__("Perplexity", { source: translationSources.uiComponents })} onClick={() => handleShare('Perplexity')} />
      </div>
    );

    return (
      <Root open={isOpen} onOpenChange={setIsOpen}>
        <Trigger asChild>
          {children}
        </Trigger>
        <Portal>
          <Content className="z-50 bg-white dark:bg-gray-800 p-2 rounded-md shadow-lg z-[60]">
            {content}
          </Content>
        </Portal>
      </Root>
    );
  }
);

SharePopover.displayName = 'SharePopover';

export default SharePopover;
// End of Selectio
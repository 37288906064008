import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {FiDownload} from 'react-icons/fi';
import {flip, offset, shift, useClick, useDismiss, useFloating, useInteractions} from '@floating-ui/react';
import {useSettings} from '@/contexts/SettingsContext';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface AudioPlayerProps {
    audioSrc: string;
    className?: string;
    autoplay?: boolean;
    onPlay?: () => void;
    onPause?: () => void;
}

const PLAYBACK_SPEEDS = [0.5, 0.75, 1, 1.25, 1.5, 2, 3, 5];

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioSrc, className = '', autoplay = false, onPlay, onPause }) => {
    const {playbackSpeed, updateSettings} = useSettings();
    const [currentSpeed, setCurrentSpeed] = useState(playbackSpeed);
    const [isSpeedMenuOpen, setIsSpeedMenuOpen] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);

    const {x, y, strategy, refs, context} = useFloating({
        open: isSpeedMenuOpen,
        onOpenChange: setIsSpeedMenuOpen,
        middleware: [offset(5), flip({fallbackAxisSideDirection: 'start'}), shift({padding: 5})],
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = currentSpeed;
        }
    }, [currentSpeed]);

    const handleSpeedChange = (speed: number) => {
        setCurrentSpeed(speed);
        setIsSpeedMenuOpen(false);
        updateSettings({playbackSpeed: speed});
    };

    const handleDownload = useCallback(() => {
        const link = document.createElement('a');
        link.href = audioSrc;
        link.download = 'audio.mp3';
        link.click();
    }, [audioSrc]);

    const translationOptions: TranslationOptions = { source: 'audio' };

    return (
        <div className={`audio-player ${className} w-full font-sans`}>
            <div className="flex items-center bg-gray-100 dark:bg-[#38393a] rounded-md overflow-hidden">
                <audio
                    ref={audioRef}
                    controls
                    src={audioSrc}
                    autoPlay={autoplay}
                    onPlay={onPlay}
                    onPause={onPause}
                    className="flex-grow [&::-webkit-media-controls-panel]:bg-transparent"
                    controlsList="nodownload nofullscreen noremoteplayback"
                />
                <div className="flex items-center space-x-4 px-4">
                    <div>
                        <button
                            ref={refs.setReference}
                            {...getReferenceProps()}
                            className="flex items-center justify-center text-gray-600 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors duration-200"
                            title={__("Change playback speed", translationOptions)}
                        >
                            <span className="text-sm font-medium">{currentSpeed}x</span>
                        </button>
                        {isSpeedMenuOpen && createPortal(
                            <div
                                ref={refs.setFloating}
                                {...getFloatingProps()}
                                style={{
                                    position: strategy,
                                    top: y ?? 0,
                                    left: x ?? 0,
                                    width: 'max-content',
                                }}
                                className="bg-white dark:bg-gray-700 shadow-md rounded-md py-1 z-[9999]"
                            >
                                {PLAYBACK_SPEEDS.map((speed) => (
                                    <button
                                        key={speed}
                                        onClick={() => handleSpeedChange(speed)}
                                        className={`block w-full text-left px-4 py-2 text-sm ${
                                            currentSpeed === speed
                                                ? 'text-indigo-600 dark:text-indigo-400 font-medium'
                                                : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600'
                                        }`}
                                    >
                                        {speed}x
                                    </button>
                                ))}
                            </div>,
                            document.body
                        )}
                    </div>
                    <button
                        onClick={handleDownload}
                        className="text-gray-600 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors duration-200"
                        title={__("Download audio", translationOptions)}
                    >
                        <FiDownload size={18}/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AudioPlayer;